<template>
    <div class="opt-wrapper">
        <label :for="id" :class="labelClass">{{ $t(label) }}</label>
        <div class="otp-section">
            <input
                :id="id"
                type="text"
                class="otp-input"
                @input="$emit('input', $event.target.value)"
                :maxlength="maxlength"
            />
            <button
                type="button"
                :disabled="isWaitingReSendOTP"
                class="send-otp-btn"
                @click="onClick"
            >
                {{
                    isWaitingReSendOTP
                        ? `${$t(waitText)} ${formattedTime}`
                        : $t(sendOtpText)
                }}
            </button>
        </div>
    </div>
</template>

<script>
import { TIME_RESENT_OPT } from '../const';

export default {
    props: {
        id: { type: String, default: 'otp' },
        modelValue: { type: String },
        label: { type: String, default: 'OTP' },
        maxlength: { type: Number, default: 7 },
        waitText: { type: String, default: 'wait' },
        sendOtpText: { type: String, default: 'sentOTP' },
        handleSendOTP: { type: Function },
        labelClass: { type: String },
    },
    data() {
        return {
            otp: '',
            isWaitingReSendOTP: false,
            reSendOPT: TIME_RESENT_OPT,
            timer: null,
        };
    },
    computed: {
        formattedTime() {
            const minutes = Math.floor(this.reSendOPT / 60);
            const seconds = this.reSendOPT % 60;
            return `${minutes}:${seconds.toString().padStart(2, '0')}`;
        },
    },
    methods: {
        async onClick() {
            const res = await this.handleSendOTP();
            if (res.status) {
                this.isWaitingReSendOTP = true;
                this.timer = setInterval(() => {
                    if (this.reSendOPT > 0) {
                        // eslint-disable-next-line no-plusplus
                        this.reSendOPT--;
                    } else {
                        this.resetCountdown();
                    }
                }, 1000);
                this.$emit('sendOtp');
            }
        },
        resetCountdown() {
            clearInterval(this.timer);
            this.timer = null;
            this.reSendOPT = 60;
            this.isWaitingReSendOTP = false;
        },
    },
};
</script>

<style scoped lang="scss">
$modal-bg-color: rgba(0, 0, 0, 0.5);
$white-color: #fff;
$border-color: #ddd;
$text-color: #333;
$secondary-text-color: #999;
$primary-color: #0087cc;
$primary-hover-color: #0056b3;
$error-color: red;
$gap-desktop: 10px;
$gap-mobie: 5px;
$radius-button: 72px;

.opt-wrapper {
    label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0px;
        color: #a4a4a4;
    }
}

.otp-section {
    display: flex;
    gap: 10px;
    align-items: center;

    .otp-input {
        flex: 1;
        padding: 10px;
        border: 1px solid $border-color;
        border-radius: 8px;
        font-size: 14px;
    }

    .send-otp-btn {
        padding: 10px 15px;
        background: $white-color;
        color: $primary-color;
        border: 1px solid $primary-color;
        border-radius: $radius-button;
        cursor: pointer;

        &:hover {
            background: $primary-hover-color;
            color: $white-color;
        }
    }
}

@media (max-width: 768px) {

  .opt-wrapper {
      label {
        font-size: 16px;
      }
    }
    .otp-section {
        .otp-input {
            width: 80%;
        }

        .send-otp-btn {
            font-size: 14px;
        }
    }
}
</style>
