<template>
    <div v-if="open" class="modal">
        <div class="modal-content">
            <span class="close" @click="closeModal">
                <CloseIcon color="#333" />
            </span>
            <div class="modal-body">
                <img src="../images/payment_succes.png" alt="payment_succes" />
                <p>{{ $t('paymentSuccess') }}</p>
                <div>
                    <button @click="handleRedirect" class="purchased-btn">
                        {{ $t('purchased') }}
                    </button>
                    <button @click="closeModal" class="close-btn">
                        {{ $t('close') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CloseIcon from '../icons/CloseIcon.vue';

export default {
    name: 'ModalPaymentSuccess',
    components: { CloseIcon },
    props: {
        open: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        closeModal() {
            this.$store.commit('marketplaceNft/UPDATE_IS_BUY_SUCCESS');
        },
        handleRedirect() {
            this.$router.push({ name: 'PurchasedProducts' });
            this.closeModal();
        },
    },
};
</script>
<style lang="scss" scoped>
$modal-bg-color: rgba(0, 0, 0, 0.5);
$white-color: #fff;
$border-color: #ddd;
$text-color: #333;
$secondary-text-color: #999;
$primary-color: #0087CC;
$primary-hover-color: #0056b3;
$sucess-color: #10cb48;

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $modal-bg-color;
    z-index: 1000;

    .modal-content {
        .close {
            position: absolute;
            z-index: 9;
            top: 5px;
            right: 10px;
            cursor: pointer;
        }

        background: $white-color;
        border-radius: 12px;
        padding: 20px;
        width: 600px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        .modal-body {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            img {
                width: 80%;
            }

            p {
                color: $sucess-color;
                font-size: 32px;
                font-weight: 700;
                line-height: 36px;
            }

            div {
                display: flex;
                justify-content: center;
                gap: 10px;
            }

            .purchased-btn,
            .close-btn {
                width: 200px;
                padding: 15px;
                background: $primary-color;
                color: $white-color;
                border: none;
                border-radius: 8px;
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
                text-align: center;

                &:hover {
                    background: $primary-hover-color;
                }
            }
            .purchased-btn {
                background: $white-color;
                color: $primary-color;
                border: 1px solid $primary-color;

                &:hover {
                    color: $white-color;
                  }
            }
        }
    }
}

@media (max-width: 768px) {
    .modal {
        .modal-content {
            width: 80%;
            max-height: 90%;
            gap: 8px;
            padding: 10px;

            .modal-body {
                padding: 10px;
                gap: 10px;

                img {
                    width: 50%;
                }

                p {
                    font-size: 24px;
                    line-height: 24px;
                }

                div {
                    display: flex;
                    justify-content: center;
                    gap: 5px;
                }

                .purchased-btn,
                .close-btn {
                    width: 130px;
                    padding: 15px;
                    font-size: 12px;
                    margin-top: px;
                }
            }
        }
    }
}
</style>
