<template>
    <div class="card" @click="$emit('click')">
        <div class="card-header">
            <img :src="product.itemData.photoUrl" alt="beeg" />
        </div>
        <div class="card-content">
            <p class="content-seller">
                {{ $t('seller') }}: #{{ product.userId }}
            </p>
            <p class="content-name">
                {{ product.itemData.name }}
                <span v-if="product.itemData.nftNumber">{{
                    `- ${product.itemData.nftNumber}`
                }}</span>
            </p>
            <div class="content-price">
                <p>{{ $t('price') }}:</p>
                <div class="content-price__info">
                    <div class="info">
                        <span>{{ (product.price.xinCoin).toLocaleString('en-US', {
                          maximumFractionDigits: 4,
                        }) }}</span>
                        <img src="@/assets/images/logo/xin.png" alt="Icon" />
                    </div>
                    <div class="info">
                        <span>{{ (product.price.USDT).toLocaleString('en-US', {
                          maximumFractionDigits: 4,
                        }) }}</span>
                        <img src="@/assets/images/logo/usdt.png" alt="Icon" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
export default {
    name: 'CartProduct',
    props: {
        product: {
            type: Object,
        },
    },
};
</script>
<style lang="scss" scoped>
$white-color: #fff;
$border-color: #ddd;
$text-color: #a4a4a4;
$secondary-text-color: #999;
$primary-color: #0087CC;
$primary-hover-color: #0056b3;

.card {
    gap: 16px;
    border-radius: 16px;
    border: 1px solid #00D1FF;
    cursor: pointer;
    // min-width: 280px;

  &:hover {
      transform: scale(1.03);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .card-header {
        width: 100%;
        height: 65%;
        min-height: 240px;
        max-height: 240px;
        border-radius: 16px;
        background: linear-gradient(209.87deg, #27abf2 1.29%, #070664 104.28%),
            no-repeat;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    .card-content {
        padding: 4px 16px 20px 16px;
        width: 100%;

        .content-seller {
            color: $text-color;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            letter-spacing: 0.05em;
            text-align: left;
            text-decoration-skip-ink: none;
            margin-bottom: 2px;
        }

        .content-name {
            font-size: 20px;
            font-weight: 600;
            text-decoration-skip-ink: none;
            color: $primary-color;
            height: 48px;
            line-height: 24px;
        }

        .content-price {
            display: flex;
            justify-content: space-between;

            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 0px;
                margin-top: 3px;
            }

            .content-price__info {
                display: flex;
                flex-direction: column;
                gap: 8px;
                text-align: right;

                .info {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                }

                .info:first-child {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 24px;
                    img {
                        width: 20px;
                    }
                }

                .info:last-child {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                }

                img {
                    margin-left: 4px;
                    object-fit: contain;
                    width: 18px;
                }
            }
        }
    }
}
</style>
