<template>
    <div class="history-container">
        <div class="wrap" v-if="MarketStatistics">
            <div v-for="(item, index) in MarketStatistics" :key="index">
                <div class="title">
                    <span v-if="item._id === 'all'">{{
                        $t('totalRevenue')
                    }}</span>
                    <span v-if="item._id === 'node'">{{
                        $t('revenueNode')
                    }}</span>
                    <span v-if="item._id === 'nft'">{{
                        $t('revenueNFT')
                    }}</span>
                </div>
                <div class="revenue">
                    <div>
                        {{ item.totalXinCoin }}
                        <img
                            src="@/assets/images/logo/xin.png"
                            alt="logo-xin"
                        />
                    </div>
                    <div>
                        {{ item.totalUSDT }}
                        <img
                            src="@/assets/images/logo/usdt.png"
                            alt="logo-usdt"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="history-list mt-4">
            <div class="header">
                <h3>{{ $t('historyInformation') }}</h3>
            </div>
            <FilterComponent
                :dispatchGetList="dispatchGetList"
                :commitParams="commitParams"
                :getterParams="mapGettersParams"
            />
            <div class="table" v-if="SoldItemList">
                <b-table
                    responsive
                    striped
                    hover
                    :items="SoldItemList.results"
                    :fields="fields"
                >
                    <template #empty>
                        <h4>No data</h4>
                    </template>
                    <template #head()="{ label }">
                        {{ $t(label) }}
                    </template>
                    <template #cell(name)="data">
                        <strong>
                            {{ data.item.itemData.name }}
                        </strong>
                    </template>
                    <template #cell(thumbnail)="data">
                        <div class="thumbnail">
                            <img :src="data.item.itemData.photoUrl" alt="" />
                        </div>
                    </template>
                    <template #cell(currency)="data">
                        <div v-if="data.item.marketData.currency === 'xinCoin'">
                            XIN
                        </div>
                        <div v-if="data.item.marketData.currency === 'USDT'">
                            USDT
                        </div>
                    </template>
                    <template #cell(date)="data">
                        <div v-if="data.item.action.createdAt">
                            {{
                                new Date(
                                    data.item.action.createdAt,
                                ).toLocaleDateString()
                            }}
                        </div>
                    </template>
                    <template #cell(price)="data">
                        {{ getPrice(data.item.marketData) }}
                    </template>
                    <template #cell(status)="data">
                        <div
                            class="pending"
                            v-if="data.item.marketData.status === 1"
                        >
                            {{ $t('available') }}
                        </div>
                        <div
                            class="success"
                            v-if="data.item.marketData.status === 2"
                        >
                            {{ $t('sold') }}
                        </div>
                        <div
                            class="cancel"
                            v-if="data.item.marketData.status === 3"
                        >
                            Cancel
                        </div>
                    </template>
                    <template #cell(categories)="data">
                        <div v-if="data.item.itemData.nodeId">XNode</div>
                        <div v-else-if="data.item.itemData.nftId">X-NFT</div>
                        <div v-else>Voucher</div>
                    </template>
                    <template #cell(action)="data">
                        <div
                            v-if="data.item.action.userId"
                            class="detail-history"
                            @click="detailModal(data)"
                        >
                            {{ $t('detail') }}
                        </div>
                    </template>
                </b-table>
                <div class="pagination" v-if="SoldItemList.totalPages > 1">
                    <Pagination
                        :page="SoldItemList.page"
                        :totalPages="SoldItemList.totalPages"
                        :handlePrevPage="handlePrevPage"
                        :handleNextPage="handleNextPage"
                    />
                </div>
            </div>
            <EmptyData :list="SoldItemList.results" />
        </div>

        <b-modal id="history-detail-modal" hide-header hide-footer centered>
            <div v-if="selectedData">
                <div
                    :class="['left', { bg: selectedData.item.itemData.nodeId }]"
                >
                    <img :src="selectedData.item.itemData.photoUrl" alt="" />
                </div>
                <div class="right">
                    <div class="title">
                        {{ selectedData.item.itemData.name }}
                        <span v-if="selectedData.item.itemData.nftNumber"
                            >- {{ selectedData.item.itemData.nftNumber }}</span
                        >
                        <span v-if="selectedData.item.itemData.nodePerformance">
                            -
                            {{ selectedData.item.itemData.nodePerformance }} HZ
                        </span>
                    </div>
                    <div class="detail-info">
                        <div class="price">
                            <div>
                                {{ $t('price') }}:
                                <span
                                    v-if="
                                        selectedData.item.marketData.price
                                            .xinCoin
                                    "
                                >
                                    {{
                                        TruncateToDecimals2(
                                            selectedData.item.marketData.price
                                                .xinCoin,
                                        )
                                    }}
                                    <img
                                        src="@/assets/images/logo/xin.png"
                                        alt=""
                                    />
                                </span>
                            </div>
                            <div v-if="selectedData.item.marketData.price.USDT">
                                {{
                                    TruncateToDecimals2(
                                        selectedData.item.marketData.price.USDT,
                                    )
                                }}
                                <img
                                    src="@/assets/images/logo/usdt.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div
                            class="lifetime"
                            v-if="selectedData.item.itemData.nodeExpired"
                        >
                            {{ $t('expired') }}:
                            <span>{{
                                new Date(
                                    selectedData.item.itemData.nodeExpired,
                                ).toLocaleString()
                            }}</span>
                        </div>
                        <div
                            class="mining"
                            v-if="selectedData.item.itemData.nodePerformance"
                        >
                            {{ $t('miningCapacity') }}:
                            <span
                                >{{
                                    selectedData.item.itemData.nodePerformance
                                }}
                                Hz/h
                            </span>
                        </div>
                        <div
                            class="nft-number"
                            v-if="selectedData.item.itemData.nftNumber"
                        >
                            {{ $t('number') }}:
                            <span>{{
                                selectedData.item.itemData.nftNumber
                            }}</span>
                        </div>
                        <div
                            class="nft-percentage"
                            v-if="selectedData.item.itemData.percentageOfHz"
                        >
                            {{ $t('percentage') }}:
                            <span
                                >{{
                                    selectedData.item.itemData.percentageOfHz
                                }}
                                %</span
                            >
                        </div>
                        <div class="buyer-info">
                            Customer Information:
                            <div>
                                <div>
                                    {{
                                        selectedData.item.action.userData.refId
                                    }}
                                </div>
                                <div>
                                    {{
                                        selectedData.item.action.userData.email
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="time">
                            {{ $t('time') }}:
                            <span>{{
                                new Date(
                                    selectedData.item.updatedAt,
                                ).toLocaleString()
                            }}</span>
                        </div>
                        <div class="currency">
                            {{ $t('currency') }}:
                            <span
                                v-if="
                                    selectedData.item.marketData.currency ===
                                    'xinCoin'
                                "
                                >XIN
                                <img src="@/assets/images/logo/xin.png" alt=""
                            /></span>
                            <span
                                v-else-if="
                                    selectedData.item.marketData.currency ===
                                    'USDT'
                                "
                                >USDT
                                <img src="@/assets/images/logo/usdt.png" alt=""
                            /></span>
                        </div>
                    </div>
                </div>
            </div>

            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                class="close-modal"
                @click="$bvModal.hide('history-detail-modal')"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterComponent from '../../../../components/marketplace/share/FilterComponents.vue';
import {
    COMMIT_CHANGE_QUERY_MYMARKET_HISTORY_PRODUCTS,
    GETTTER_PARAMS_QUERY_MYMARKET_HISTORY_PRODUCT,
    DISPATCH_GET_MYMARKET_HISTORY_PRODUCTS,
} from '../../../../components/marketplace/const';
import Pagination from '../../../../components/marketplace/share/Pagination.vue';
import EmptyData from '../../../../components/marketplace/share/EmptyData.vue';

export default {
    components: {
        Pagination,
        FilterComponent,
        EmptyData,
    },
    data() {
        return {
            fields: [
                {
                    key: 'thumbnail',
                    label: 'thumbnail',
                },
                {
                    key: 'name',
                    label: 'name',
                },
                {
                    key: 'categories',
                    label: 'categories',
                },
                // {
                //     key: 'description',
                //     label: 'Description',
                // },
                {
                    key: 'price',
                    label: 'price',
                },
                {
                    key: 'currency',
                    label: 'currency',
                },
                {
                    key: 'date',
                    label: 'date',
                },
                {
                    key: 'status',
                    label: 'status',
                },
                {
                    key: 'action',
                    label: 'action',
                },
            ],
            selected: 'desc',
            selectedData: null,
            page: 1,
            limit: 10,
            myValue: null,
            dispatchGetList: DISPATCH_GET_MYMARKET_HISTORY_PRODUCTS,
            mapGettersParams: GETTTER_PARAMS_QUERY_MYMARKET_HISTORY_PRODUCT,
            commitParams: COMMIT_CHANGE_QUERY_MYMARKET_HISTORY_PRODUCTS,
        };
    },

    computed: {
        ...mapGetters({
            MarketStatistics: 'marketplace/MarketStatistics',
            ItemToSell: 'marketplace/ItemToSell',
            SoldItemList: 'marketplace/getMyMarketHistoryList',
            UserInfo: 'info/UserInfo',
        }),
    },

    methods: {
        detailModal(input) {
            this.selectedData = input;
            this.$bvModal.show('history-detail-modal');
        },

        async sortItem() {
            await this.$store.dispatch('marketplace/getSoldItem', {
                sortBy: this.selected,
                page: this.page,
            });
        },

        onChangePage(payload) {
            this.page = payload;
            this.reqItemToSold();
        },

        reqItemToSold() {
            return this.$store.dispatch('marketplace/getSoldItem', {
                page: this.page,
            });
        },

        getPrice(input) {
            if (input.currency === 'xinCoin') {
                return input.price.xinCoin;
            }
            return input.price.USDT;
        },

        async handlePrevPage() {
            await this.$store.dispatch(this.dispatchGetList, {
                page: Number(this.SoldItemList.page) - 1,
            });
        },
        async handleNextPage() {
            await this.$store.dispatch(this.dispatchGetList, {
                page: Number(this.SoldItemList.page) + 1,
            });
        },
    },

    mounted() {
        this.$store.dispatch('marketplace/getItemToSell', {
            itemGroup: 'node',
        });
        this.$store.dispatch('marketplace/getMyMarketHistory');
        this.$store.dispatch('marketplace/getMarketStatistics');
    },
};
</script>

<style lang="scss" scoped>
.history-container {
    .wrap {
        display: flex;
        justify-content: space-evenly;
        gap: 32px;
        margin-top: 24px;

        > div {
            width: 484px;
            background: #fff;
            border: 1px solid #d8d8d8;
            border-radius: 20px;
            padding: 32px;
        }

        .title {
            color: #3c3c3c;
        }

        .revenue {
            margin-top: 4rem;
            text-align: right;
            font-size: 24px;
            font-weight: 600;

            > div {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 7px;
                img {
                    width: 24px;
                }
            }
        }
    }

    .history-list {
        padding: 32px;
        border-radius: 20px;
        background: #fff;
        border: 1px solid #d8d8d8;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h3 {
                font-weight: 700;
            }
            .inputs {
                display: flex;
                align-items: center;
                gap: 15px;
                .search,
                .sort {
                    input {
                        border-radius: 8px;
                        color: #8e8e93;
                        font-weight: 600;
                        background: #fff;
                    }

                    select {
                        width: 120px;
                        border-radius: 8px;
                        color: #8e8e93;
                        font-weight: 600;
                        background: #fff
                            url('../../../../assets/images/icons/icon-down.svg')
                            no-repeat right 10px;
                    }
                }

                .add-product {
                    border-radius: 8px;
                    border: 1px solid #ced4da;
                    padding: 6.4px 18px;
                    color: #8e8e93;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    gap: 7px;
                    cursor: pointer;
                }
            }
        }

        .table {
            margin-top: 1rem;

            .success,
            .pending,
            .cancel {
                width: 110px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                gap: 7px;
                padding: 2px 10px;

                &:before {
                    content: '';
                    display: block;
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                }
            }

            .success {
                background: #ecfdf3;
                border: 1px solid #abefc6;
                color: #067647;

                &:before {
                    background: #17b26a;
                }
            }

            .pending {
                background: #cdeeff;
                border: 1px solid #0087cc;
                color: #0087cc;

                &:before {
                    background: #0087cc;
                }
            }

            .cancel {
                background: #fdecec;
                border: 1px solid #efabab;
                color: #d51111;

                &:before {
                    background: #d51111;
                }
            }

            .detail-history {
                cursor: pointer;
                font-weight: 700;
                text-decoration: underline;
                color: #0087cc;
            }
            .thumbnail {
                img {
                    width: 40px;
                }
            }
            .currency {
                color: #0087cc;
                display: flex;
                gap: 6px;
                img {
                    width: 24px;
                }
            }
        }
    }
}
</style>

<style lang="scss">
#history-detail-modal {
    .modal-dialog {
        max-width: 1000px !important;

        @media (max-width: 991px) {
            padding: 0 15px;
        }

        .modal-body {
            border-radius: 22px;

            > div {
                display: flex;
                align-items: center;
                gap: 2rem;
                .bg {
                    width: 400px;
                    height: 400px;
                    background-image: url('../../../../assets/images/background/bg.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .right {
                    flex-grow: 1;

                    .info {
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        .right {
                            .top {
                                font-size: 14px;
                            }

                            .bottom {
                                font-size: 20px;
                                font-weight: 600;
                                color: #3c3c3c;
                            }
                        }
                    }

                    .title {
                        font-size: 32px;
                        color: #0087cc;
                        font-weight: 700;
                    }

                    .price {
                        margin-top: 16px;
                        border-bottom: 1px solid #d8d8d8;
                        padding-bottom: 10px;
                        img {
                            width: 24px;
                        }

                        div:nth-child(1) {
                            font-size: 24px;
                            display: flex;
                            justify-content: space-between;

                            span {
                                font-size: 32px;
                                display: flex;
                                align-items: center;
                                gap: 6px;
                            }
                        }

                        div:nth-child(2) {
                            font-size: 24px;
                            text-align: right;
                        }
                    }

                    .lifetime,
                    .mining,
                    .time,
                    .currency,
                    .status,
                    .nft-number,
                    .nft-percentage,
                    .buyer-info {
                        display: flex;
                        justify-content: space-between;
                        font-size: 16px;
                        margin-top: 14px;
                        font-weight: 600;

                        > div {
                            div:nth-child(1) {
                                font-weight: 400;
                            }
                        }
                    }
                    .currency {
                        img {
                            width: 24px;
                        }
                    }

                    .mining,
                    .nft-percentage,
                    .buyer-info {
                        padding-bottom: 10px;
                        border-bottom: 1px solid #d8d8d8;
                    }

                    .lifetime,
                    .mining,
                    .nft-number,
                    .nft-percentage {
                        span {
                            color: #0087cc;
                        }
                    }

                    .currency,
                    .nft-number,
                    .nft-percentage {
                        span {
                            color: #0087cc;
                            display: flex;
                            align-items: center;
                            gap: 6px;
                        }
                    }

                    .buyer-info {
                        > div {
                            text-align: right;
                        }
                    }

                    .status {
                        .success,
                        .pending,
                        .cancel {
                            width: 110px;
                            border-radius: 20px;
                            display: flex;
                            align-items: center;
                            gap: 7px;
                            padding: 2px 10px;

                            &:before {
                                content: '';
                                display: block;
                                width: 6px;
                                height: 6px;
                                border-radius: 100%;
                            }
                        }

                        .success {
                            background: #ecfdf3;
                            border: 1px solid #abefc6;
                            color: #067647;

                            &:before {
                                background: #17b26a;
                            }
                        }

                        .pending {
                            background: #cdeeff;
                            border: 1px solid #0087cc;
                            color: #0087cc;

                            &:before {
                                background: #0087cc;
                            }
                        }

                        .cancel {
                            background: #fdecec;
                            border: 1px solid #efabab;
                            color: #d51111;

                            &:before {
                                background: #d51111;
                            }
                        }
                    }
                }
            }

            @media (max-width: 991px) {
                .left {
                    display: none;
                }

                .right {
                    width: 100%;

                    .info {
                        .right {
                            .bottom {
                                font-size: 16px;
                            }
                        }
                    }

                    .title {
                        font-size: 24px;
                    }

                    .detail-info {
                        .price {
                            div:nth-child(1) {
                                font-size: 18px;

                                span {
                                    font-size: 24px;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 575px) {
                .right {
                    .buyer-info {
                        flex-direction: column;
                    }
                }
            }

            .close-modal {
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;
                transition: 0.2s;
                font-size: 24px;
                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }
}
</style>
