<template>
    <div class="add-product-form">
        <router-link :to="{ name: 'Product' }" class="back">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1"
                />
            </svg>
            {{ $t('goBack') }}
        </router-link>
        <form @submit.prevent="handleSellItem">
            <div class="left">
                <div>
                    <label class="label-typo" for="categories">{{
                        $t('categories')
                    }}</label>
                    <b-form-select
                        class="rounded-md"
                        id="categories"
                        :options="categoriesOptions"
                        v-model="categories"
                        required
                        @change="sortItem"
                    ></b-form-select>
                </div>
                <div class="product">
                    <label class="label-typo" for="product">{{
                        $t('product')
                    }}</label>
                    <div>
                        <b-form-select
                            class="rounded-md"
                            id="product"
                            v-model="selectedProduct"
                        >
                            <b-form-select-option
                                disabled
                                :value="null"
                                key="null"
                            >
                                {{ $t('chooseProduct') }}
                            </b-form-select-option>
                            <b-form-select-option
                                v-for="(item, index) in ItemToSell.results"
                                :key="index"
                                :value="item._id"
                            >
                                <template v-if="categories === 'nft'">
                                    {{ item.itemData.name }} -
                                    {{ item.number }}
                                </template>
                                <template v-else>
                                    {{ item.itemData.name }}
                                </template>
                            </b-form-select-option>
                        </b-form-select>
                    </div>
                </div>
                <div class="product-detail" v-if="filteredProduct">
                    <div class="title label-typo">{{ $t('detail') }}</div>
                    <div class="detail">
                        <div v-for="item in filteredProduct" :key="item._id">
                            <div v-if="item.nodeId">
                                <div
                                    :class="[
                                        'thumbnail',
                                        { bg: item.itemData.nodeId },
                                    ]"
                                >
                                    <img :src="item.itemData.photoUrl" alt="" />
                                </div>
                                <div class="info">
                                    <div>
                                        {{ $t('name') }}:
                                        <span>{{ item.itemData.name }}</span>
                                    </div>
                                    <div>
                                        {{ $t('performance') }}:
                                        <span>{{ item.performance }} HZ</span>
                                    </div>
                                    <div>
                                        {{ $t('expired') }}:
                                        <span>{{
                                            new Date(
                                                item.expired,
                                            ).toLocaleString()
                                        }}</span>
                                    </div>
                                    <div class="status">
                                        {{ $t('status') }}:
                                        <SwitchActive
                                            :isActice="item.actived"
                                            :onClick="
                                                () => deactivateXNode(item)
                                            "
                                        />
                                    </div>
                                    <div
                                        class="note"
                                        v-if="item.actived === true"
                                    >
                                        {{ $t('warning') }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.nftId">
                                <div class="thumbnail">
                                    <img :src="item.itemData.photoUrl" alt="" />
                                </div>
                                <div class="info">
                                    <div>
                                        Name:
                                        <span>{{ item.itemData.name }}</span>
                                    </div>
                                    <div>
                                        Number:
                                        <span>{{ item.number }}</span>
                                    </div>
                                    <div>
                                        Percentage:
                                        <span
                                            >{{
                                                item.itemData.percentageOfHz
                                            }}
                                            %</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div>
                    <div class="price">
                        <label class="label-typo" for="price">{{
                            $t('price')
                        }}</label>
                        <b-form-input
                            class="rounded-md"
                            required
                            id="price"
                            min="0"
                            type="number"
                            placeholder="Enter your price"
                            v-model="price"
                            @input="handleSwapCurrency"
                        ></b-form-input>
                        <div class="custom-dropdown">
                            <div
                                class="custom-dropdown-toggle"
                                @click="toggleDropdown"
                            >
                                <div class="img-currency">
                                    <img
                                        v-if="
                                            selectedCurrency.value === 'xinCoin'
                                        "
                                        src="@/assets/images/logo/xin.png"
                                        alt=""
                                    />
                                    <img
                                        v-if="selectedCurrency.value === 'USDT'"
                                        src="@/assets/images/logo/usdt.png"
                                        alt=""
                                    />
                                </div>
                                <div>
                                    {{ selectedCurrency.text || 'Currency' }}
                                </div>
                                <span class="arrow"></span>
                            </div>
                            <div v-if="isOpen" class="custom-dropdown-menu">
                                <div
                                    v-for="option in currencyOptions"
                                    :key="option.value"
                                    class="custom-dropdown-item"
                                    @click="selectOption(option)"
                                >
                                    {{ option.text }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="price-wapper">
                        <ApproximationIcon /> <span>{{ currencySwap }}</span>
                        <span>{{ getLabelSwapping() }}</span>
                    </div>
                </div>
                <div class="fee">
                    <span>
                        {{ $t('fee') }}
                    </span>
                    <span> {{ Fees.marketFeeByPercent }}% </span>
                </div>

                <div class="income">
                    <span>
                        {{ $t('amountReceived') }}
                    </span>
                    <span>
                        {{ handleComputeFee() }} {{ selectedCurrency.text }}
                    </span>
                </div>

                <div class="otp">
                    <SentOTP
                        v-model="otp"
                        labelClass="label-typo"
                        :handleSendOTP="reqOTP"
                        :maxlength="7"
                    />
                </div>
                <div class="upload">
                    <button>{{ $t('uploadSell') }}</button>
                </div>
            </div>
        </form>

        <PopupConfirm
            @update:open="isPopupOpen = $event"
            :open="isPopupOpen"
            :onClickConfirm="sellItem"
            :params="params"
            :title="$t('uploadSell')"
            :submitButtonTitle="$t('uploadSell')"
            :description="$t('areYouSureWantToSellTheProduct')"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PopupConfirm from '../../../../components/marketplace/myMarket/PopupConfirm.vue';
import ApproximationIcon from '../../../../components/marketplace/icons/ApproximationIcon.vue';
import SwitchActive from '../../../../components/marketplace/myMarket/SwitchActive.vue';
import SentOTP from '../../../../components/marketplace/share/SentOTP.vue';

export default {
    components: { PopupConfirm, ApproximationIcon, SwitchActive, SentOTP },
    data() {
        return {
            categories: 'node',
            price: null,
            otp: null,
            currency: null,
            categoriesOptions: [
                {
                    value: null,
                    text: this.$t('chooseCategory'),
                    disabled: true,
                },
                { value: 'node', text: 'XNode' },
                { value: 'nft', text: 'X-NFT' },
                // { value: 'voucher', text: 'Voucher' },
            ],
            currencyOptions: [
                { value: 'xinCoin', text: 'XIN' },
                { value: 'USDT', text: 'USDT' },
            ],
            nameOption: [],
            isOpen: false,
            selectedCurrency: { value: 'xinCoin', text: 'XIN' },
            selectedProduct: null,
            isPopupOpen: false,
            params: {},
            currencySwap: 0,
            labelSwap: '',
        };
    },
    computed: {
        ...mapGetters({
            ItemToSell: 'marketplace/ItemToSell',
            Rates: 'wallet/Rates',
            Fees: 'wallet/Fees',
        }),

        filteredProduct() {
            return this.ItemToSell.results.filter(
                (el) => el._id === this.selectedProduct,
            );
        },
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectOption(option) {
            this.selectedCurrency = option;
            this.isOpen = false;
        },

        handleSellItem() {
            this.$bvModal.show('modal-confirm');
            this.params = {
                otp: Number(this.otp),
                itemGroup: this.categories,
                currency: this.selectedCurrency.value,
                price: this.price,
                itemOid: this.selectedProduct,
            };
        },
        async sellItem() {
            const res = await this.$store.dispatch('marketplace/onSellItem', {
                otp: Number(this.otp),
                itemGroup: this.categories,
                currency: this.selectedCurrency.value,
                price: this.price,
                itemOid: this.selectedProduct,
            });
            if (res.status) {
                this.$router.push({ name: 'Product' });
                this.$store.dispatch('marketplace/getSoldItem');
            }
        },

        async sortItem() {
            await this.$store.dispatch('marketplace/getItemToSell', {
                itemGroup: this.categories,
            });
        },

        reqOTP() {
            return this.$store.dispatch('marketplace/getOTPSell', {
                type: 'SELL_MARKET_ITEM',
            });
        },

        clearForm() {
            this.categories = 'node';
            this.price = null;
            this.otp = null;
            this.currency = null;
        },

        async deactivateXNode(item) {
            const { _id, actived } = item;
            const path = actived
              ? 'mining/deactivateXNode'
              : 'mining/activate_node';
            const res = await this.$store.dispatch(path, {
                nodeId: _id,
            });
            if (res.status) {
                this.$store.dispatch('marketplace/getItemToSell', {
                    itemGroup: this.categories,
                });
            }
        },

        getLabelSwapping() {
            return this.selectedCurrency.value === 'xinCoin' ? 'USDT' : 'XIN';
        },

        handleSwapCurrency() {
            const swaperTitle =
                this.selectedCurrency.value === 'xinCoin'
                    ? 'xinCoin-USDT'
                    : 'USDT-xinCoin';
            const rate = this.Rates.find((item) => item.name === swaperTitle);
            this.currencySwap = this.price * (rate?.rate || 0);
        },

        handleComputeFee() {
            const fee =
                Number(this.price) *
                Number(Number(this.Fees.marketFeeByPercent) / 100);
            return this.price - fee;
        },
    },
    mounted() {
        this.$store.dispatch('marketplace/getItemToSell', {
            itemGroup: 'node',
        });
        this.$store.dispatch('info/req_getInfo');
        // this.$store.dispatch('marketplace/onSellItem');
        // this.filteredProduct;
    },
    beforeDestroy() {
        this.clearForm();
    },
    watch: {
        'selectedCurrency.value': 'handleSwapCurrency',
        'price': 'handleSwapCurrency',
    },
};
</script>

<style lang="scss" scoped>
.add-product-form {
    .back {
        margin-top: 1rem;
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        gap: 5px;
        color: #0087cc;
        transition: 0.2s;
        &:hover {
            opacity: 0.7;
        }
    }

    form {
        margin-top: 1rem;
        display: flex;
        align-items: flex-start;
        gap: 15px;

        .left {
            width: 65%;
            > div {
                margin-bottom: 16px;
            }
        }

        .right {
            flex-grow: 1;
            > div {
                margin-bottom: 16px;
            }

            .fee {
                display: flex;
                justify-content: space-between;
                font-weight: bold;
            }

            .income {
                display: flex;
                justify-content: space-between;
                font-weight: bold;
                span {
                    &:last-child {
                        font-size: 16px;
                        color: #00cc58;
                    }
                }
            }
        }

        input,
        select {
            height: 56px;
            background-color: #fff;
            border-radius: 12px;
        }

        select {
            background: #fff
                url('../../../../assets/images/icons/icon-down.svg') no-repeat
                right 20px top 20px;
        }

        .price {
            position: relative;

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type='number'] {
                -moz-appearance: textfield;
            }
            .custom-dropdown {
                position: absolute;
                display: inline-block;
                right: 20px;
                top: 40px;
                width: 100px;
            }

            .custom-dropdown-toggle {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 6px 10px;
                border-radius: 22px;
                cursor: pointer;
                background: #f1f1f1;
                font-size: 16px;

                .arrow {
                    margin-top: -2px;
                    border: solid #0087cc;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 3px;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                }

                .img-currency {
                    margin-top: -2px;

                    img {
                        width: 20px;
                    }
                }
            }

            .custom-dropdown-menu {
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                border: 1px solid #ccc;
                border-radius: 4px;
                background-color: #fff;
                z-index: 1000;
                font-size: 14px;
            }

            .custom-dropdown-item {
                padding: 6px 10px;
                cursor: pointer;
            }

            .custom-dropdown-item:hover {
                background-color: #f1f1f1;
            }
        }

        .otp {
            > div {
                position: relative;

                .get-otp {
                    background: #f1f1f1;
                    border-radius: 22px;
                    position: absolute;
                    padding: 6px 15px;
                    right: 10px;
                    top: 10px;
                    cursor: pointer;
                }
            }
        }

        .upload {
            width: 100%;
            margin: 0 auto;
            margin-top: 15px;
            button {
                width: 100%;
                background: #0087cc;
                border-radius: 22px;
                color: #fff;
                padding: 12px 0;
                outline: none;
            }
        }

        .product-detail {
            .detail {
                margin-top: 7px;
                border: 1px solid #d8d8d8;
                border-radius: 8px;
                padding: 14px;
                height: 265px;
                background: #fff;

                > div {
                    height: 100%;

                    > div {
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        height: 100%;

                        .thumbnail {
                            display: flex;
                            align-items: center;

                            // img {
                            //     width: 200px;
                            // }
                        }

                        .bg {
                            width: 280px;
                            height: 220px;
                            background-image: url('../../../../assets/images/background/bg.png');
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .info {
                            flex-grow: 1;
                            > div {
                                display: flex;
                                justify-content: space-between;
                                font-size: 18px;
                                margin-bottom: 16px;
                                span {
                                    font-weight: 600;
                                    color: #0087cc;
                                }
                            }

                            .note {
                                color: #d11b15;
                                font-weight: 600;
                                justify-content: center;
                            }

                            .status {
                                .success {
                                    color: #00cc58;
                                }

                                .fail {
                                    color: #d11b15;
                                }
                            }
                        }
                    }
                }
            }
        }
        select {
            background: #fff
                url('../../../../assets/images/icons/icon-down.svg') no-repeat
                right 4px center;
        }
        @media (max-width: 1025px) {
            flex-direction: column;

            .left,
            .right {
                width: 100%;
            }
        }

        @media (max-width: 991px) {
            .product-detail {
                .detail {
                    height: 100%;
                    > div {
                        > div {
                            flex-direction: column;

                            .thumbnail {
                                display: none;
                            }

                            .info {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.rounded-md {
    border-radius: 8px !important;
}

#price {
    padding-right: 30% !important;
}

.price-wapper {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-top: 5px;
}
</style>

<style lang="scss">
.label-typo {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
}
</style>
