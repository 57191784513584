<template>
    <div>
        <span @click="openModal" class="show-detail">
            {{ this.$t('showDetails') }}
        </span>
        <b-modal
            size="xl"
            dialog-class="modal-purchase-detail"
            :id="`${item.id}`"
            centered
            hide-footer
            hide-header
            :striped="false"
        >
            <div class="purchase-detail-container">
                <span class="close" @click="closeModal">
                    <CloseIcon color="#333" />
                </span>
                <div class="image">
                    <img
                        src="@/assets/images/background/gallery.png"
                        alt="image-background"
                        class="img-bg"
                    />
                    <img :src="item.image" alt="image" class="img-item" />
                </div>
                <div class="info">
                    <div class="header">
                        <img
                            src="@/assets/images/mockup/avatar.png"
                            alt="avatar"
                        />
                        <div>
                            <div class="id_user">{{ item.id_user }}</div>
                            <div class="name_user">{{ item.email }}</div>
                        </div>
                    </div>
                    <div class="name">
                        <div>{{ item.name }}</div>
                        <div v-if="item.category === 'nft'">-</div>
                        <div v-if="item.category === 'nft'">
                            {{ item.numberNft }}
                        </div>
                    </div>
                    <div class="price">
                        <div class="amount">
                            <span>{{ $t('amount') }}:</span>
                            <div>
                                <span v-if="item.priceXinCoin"
                                    >{{
                                        TruncateToDecimals2(item.priceXinCoin)
                                    }}
                                    <img
                                        src="@/assets/images/logo/xin.png"
                                        alt="xin-logo"
                                        class="logo"
                                    />
                                </span>
                                <span v-if="item.priceUsdt"
                                    >{{ TruncateToDecimals2(item.priceUsdt) }}
                                    <img
                                        src="@/assets/images/logo/usdt.png"
                                        alt="usdt-logo"
                                        class="logo"
                                    />
                                </span>
                            </div>
                        </div>
                        <div class="fee">
                            <span>{{ $t('fee') }}:</span>
                            <div>
                                <span v-if="item.feeXin"
                                    >{{ TruncateToDecimals2(item.feeXin) }}
                                    <img
                                        src="@/assets/images/logo/xin.png"
                                        alt="xin-logo"
                                        class="logo"
                                    />
                                </span>
                                <span v-if="item.feeUsdt"
                                    >{{ TruncateToDecimals2(item.feeUsdt) }}
                                    <img
                                        src="@/assets/images/logo/usdt.png"
                                        alt="usdt-logo"
                                        class="logo"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mining">
                        <div v-if="item.category === 'node'">
                            <div class="mining-child">
                                <span>{{ $t('lifeTime') }}:</span>
                                <span class="value"> {{ item.lifeTime }}</span>
                            </div>
                            <div class="mining-child">
                                <span>{{ $t('miningCapacity') }}:</span>
                                <span class="value"
                                    >{{ item.mining }} Hz/h</span
                                >
                            </div>
                        </div>

                        <div v-else>
                            <div class="mining-child number-nft">
                                <span>{{ $t('number-nft') }}:</span>
                                <span class="value"> {{ item.numberNft }}</span>
                            </div>
                            <div class="mining-child">
                                <span>{{ $t('bonusPercent') }}:</span>
                                <span class="value"
                                    >{{ item.percentHz }} Hz/h</span
                                >
                            </div>
                        </div>

                        <div class="image-mobile">
                            <span>{{ $t('imagesDetail') }}:</span>
                            <div>
                                <img
                                    src="@/assets/images/background/gallery.png"
                                    alt="image-background"
                                    class="img-bg"
                                />
                                <img
                                    :src="item.image"
                                    alt="image"
                                    class="img-item"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="time">
                        <div class="time-child">
                            <span>{{ $t('time') }}:</span>
                            <span class="value-time">{{
                                getDateTime(item.time)
                            }}</span>
                        </div>
                        <div class="time-child">
                            <span>{{ $t('currency') }}:</span>
                            <span class="value-currency"
                                >{{
                                    item.currency === 'xinCoin' ? 'XIN' : 'USDT'
                                }}
                                <img
                                    v-if="item.currency === 'USDT'"
                                    src="@/assets/images/logo/usdt.png"
                                    alt="usdt-logo"
                                    class="logo"
                                />

                                <img
                                    v-if="item.currency === 'xinCoin'"
                                    src="@/assets/images/logo/xin.png"
                                    alt="xin-logo"
                                    class="logo"
                                />
                            </span>
                        </div>
                        <div class="time-child">
                            <span>{{ $t('status') }}:</span>
                            <span :class="renderClassStatus(item.status)">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    viewBox="0 0 256 256"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M156 128a28 28 0 1 1-28-28a28 28 0 0 1 28 28"
                                    />
                                </svg>
                                {{
                                    item.status === 0
                                        ? $t('success')
                                        : data.item.status === 1
                                        ? $t('processing')
                                        : $t('failed')
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import CloseIcon from '../icons/CloseIcon.vue';

export default {
    props: {
        item: {
            type: Object,
            default: null,
        },
    },
    components: { CloseIcon },
    methods: {
        openModal() {
            this.$bvModal.show(`${this.item.id}`);
        },

        closeModal() {
            this.$bvModal.hide(`${this.item.id}`);
        },

        renderClassStatus(state) {
            if (state === 0) {
                return 'success';
            }

            if (state === 1) {
                return 'pending';
            }

            return 'cancel';
        },
    },
};
</script>

<style lang="scss">
.show-detail {
    cursor: pointer;
    font-weight: 700;
    color: #0087cc;
    text-decoration: underline;
}

.modal-purchase-detail {
    @media (min-width: 640px) {
        max-width: 65%;
    }

    .modal-content {
        .close {
            position: absolute;
            z-index: 9;
            top: -15px;
            right: -15px;
            cursor: pointer;
        }
        .modal-body {
            padding: 32px;
            flex-direction: row;
            border-radius: 20px;

            .purchase-detail-container {
                display: flex;
                gap: 32px;
                position: relative;

                .image {
                    background: linear-gradient(
                        209.87deg,
                        #27abf2 1.29%,
                        #070664 104.28%
                    );
                    position: relative;
                    width: 40%;
                    border-radius: 16px;

                    .img-bg {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .img-item {
                        position: absolute;
                        width: 90%;
                        height: 90%;
                        object-fit: contain;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    @media (max-width: 1280px) {
                        display: none;
                    }
                }

                .info {
                    width: 60%;

                    .header {
                        display: flex;
                        gap: 12px;
                        align-items: center;

                        img {
                            width: 40px;
                            height: 40px;
                        }

                        div {
                            .id_user {
                                font-size: 14px;
                            }

                            .name_user {
                                font-size: 20px;
                                font-weight: 600;
                                word-break: break-all;
                            }
                        }
                    }

                    .name {
                        color: #0087cc;
                        font-weight: 600;
                        font-size: 28px;
                        display: flex;
                        gap: 8px;

                        @media (max-width: 1280px) {
                            :nth-child(2) {
                                display: none;
                            }
                            :nth-child(3) {
                                display: none;
                            }
                        }
                    }

                    .price {
                        .amount {
                            padding: 16px 0 16px 0;
                            display: flex;
                            justify-content: space-between;
                            font-size: 24px;
                            font-weight: 500;

                            div {
                                font-size: 28px;
                                font-weight: 600;
                                display: flex;
                                flex-direction: column;
                                align-items: end;

                                span {
                                    .logo {
                                        width: 24px;
                                        height: 24px;
                                    }

                                    @media (max-width: 428px) {
                                        font-size: 20px;
                                    }
                                }
                            }

                            @media (max-width: 728px) {
                                font-size: 20px;

                                > div {
                                    font-size: 24px;
                                }
                            }
                        }

                        .fee {
                            padding: 16px 0 16px 0;
                            display: flex;
                            justify-content: space-between;
                            font-size: 24px;
                            font-weight: 500;

                            div {
                                font-size: 28px;
                                font-weight: 600;
                                display: flex;
                                flex-direction: column;
                                align-items: end;

                                span {
                                    .logo {
                                        width: 24px;
                                        height: 24px;
                                    }

                                    @media (max-width: 428px) {
                                        font-size: 20px;
                                    }
                                }
                            }

                            @media (max-width: 728px) {
                                font-size: 20px;

                                > div {
                                    font-size: 24px;
                                }
                            }
                        }
                    }

                    .mining {
                        border-top: 1px solid #d8d8d8;
                        border-bottom: 1px solid #d8d8d8;
                        padding: 16px 0 16px 0;
                        display: flex;
                        flex-direction: column;
                        gap: 16px;

                        .mining-child {
                            font-size: 16px;
                            font-weight: 500;
                            display: flex;
                            justify-content: space-between;

                            .value {
                                font-weight: 600;
                                font-size: 18px;
                                color: #0087cc;
                            }
                        }

                        .number-nft {
                            @media (min-width: 1280px) {
                                opacity: 0;
                            }
                        }

                        .image-mobile {
                            display: flex;
                            justify-content: space-between;

                            > div {
                                background: linear-gradient(
                                    209.87deg,
                                    #27abf2 1.29%,
                                    #070664 104.28%
                                );
                                position: relative;
                                width: 80px;
                                height: 80px;
                                border-radius: 16px;
                                font-size: 16px;
                                font-weight: 500;

                                .img-bg {
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }

                                .img-item {
                                    position: absolute;
                                    width: 90%;
                                    height: 90%;
                                    object-fit: contain;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }

                            @media (min-width: 1280px) {
                                display: none;
                            }
                        }
                    }

                    .time {
                        padding: 16px 0 16px 0;
                        display: flex;
                        flex-direction: column;
                        gap: 16px;

                        .time-child {
                            display: flex;
                            justify-content: space-between;
                            font-size: 16px;
                            font-weight: 500;
                            align-items: center;

                            .value-time {
                                font-weight: 600;
                                font-size: 18px;
                            }

                            .value-currency {
                                font-weight: 600;
                                font-size: 18px;
                                color: #0087cc;
                                display: flex;
                                gap: 8px;

                                .logo {
                                    width: 24px;
                                    height: 24px;
                                }
                            }

                            .success {
                                display: flex;
                                align-items: center;
                                width: 150px;
                                border-radius: 9999px;
                                border: 1px solid #abefc6;
                                background: #ecfdf3;
                                color: #067647;
                                width: fit-content;
                                padding: 0 15px 0 0;
                            }

                            .pending {
                                display: flex;
                                align-items: center;
                                width: 150px;
                                border-radius: 9999px;
                                border: 1px solid #efd8ab;
                                background: #fdf8ec;
                                color: #d4a60f;
                                width: fit-content;
                                padding: 0 15px 0 0;
                            }

                            .cancel {
                                display: flex;
                                align-items: center;
                                width: 150px;
                                border-radius: 9999px;
                                border: 1px solid #efabab;
                                background: #fdecec;
                                color: #d51111;
                                width: fit-content;
                                padding: 0 15px 0 0;
                            }
                        }
                    }

                    @media (max-width: 1280px) {
                        width: 100%;
                    }
                }
            }

            @media (max-width: 428px) {
                padding: 24px;
            }
        }
    }
}
</style>
