<template>
    <div class="filter-container">
        <div class="filter-function">
            <div
                :class="
                    useSearchPrimaryStyle
                        ? 'primary-search'
                        : 'secondary-search'
                "
                :style="{ 'justify-content': justifyContenStyle }"
            >
                <i class="search-icon">
                    <SearchIcon />
                </i>
                <input
                    type="text"
                    :placeholder="$t('searchByName')"
                    class="search-input"
                    @keyup.enter="handleEnter"
                    v-model="searchByName"
                />
            </div>
            <div class="select">
                <div class="select-wrapper">
                    <select
                        name="category"
                        id="category"
                        @change="handleFilterItemType"
                        v-model="selectedItemGroup"
                    >
                        <option value="" disabled selected hidden>
                            {{ $t('selectCategory') }}
                        </option>
                        <option
                            v-for="(value, key) in filters"
                            :key="key"
                            :value="value.itemGroup"
                        >
                            {{ $t(formatLabel(value.itemGroup)) }}
                        </option>
                    </select>
                </div>
                <div class="select-wrapper">
                    <select
                        name="sortby"
                        id="sortby"
                        v-model="selectedItemType"
                        @change="handleSelectedItemType"
                        :disabled="!filteredItemType || !selectedItemGroup"
                    >
                        <option value="" disabled selected hidden>
                            {{ $t('subCategory') }}
                        </option>
                        <option
                            v-for="(value, key) in filteredItemType"
                            :key="key"
                            :value="value"
                        >
                            {{ $t(formatLabel(value)) }}
                        </option>
                    </select>
                </div>
                <div class="select-wrapper">
                    <select
                        name="price"
                        id="price"
                        v-model="sortyBySelected"
                        @change="handleSortByPrice"
                    >
                        <option value="" disabled selected hidden>
                            {{ $t('sort') }}
                        </option>
                        <option
                            v-for="(value, key) in sortBy"
                            :key="key"
                            :value="value"
                        >
                            {{ $t(formatLabel(value)) }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SearchIcon from '../icons/SearchIcon.vue';
import { throttle } from 'lodash';
import {
    itemGroup,
    sortItemBy,
    formatLabel as fomart,
    DISPATCH_GET_FILTER,
} from '../const';

export default {
    name: 'FilterComponent',
    components: { SearchIcon },
    props: {
        dispatchGetList: String,
        commitParams: String,
        getterParams: String,
        useSearchPrimaryStyle: Boolean,
        justifyBetween: Boolean,
    },

    data() {
        return {
            selectedItemGroup: '',
            selectedItemType: '',
            searchByName: '',
            sortyBySelected: '',
            sortBy: sortItemBy,
            filterCategory: itemGroup,
            filteredItemType: {},
            formatLabel: fomart,
        };
    },
    computed: {
        getParamsQuery() {
            if (!this.getterParams) {
                console.warn('getterParams is not defined.');
                return null;
            }
            return this.$store.getters[this.getterParams];
        },
        justifyContenStyle() {
            return this.justifyBetween ? 'space-between' : 'flex-end';
        },
        activeFilter() {
            return (
                this.selectedItemGroup !== '' ||
                this.selectedItemType !== '' ||
                this.searchByName !== '' ||
                this.sortyBySelected !== ''
            );
        },
        ...mapGetters({
            filters: 'marketplaceNft/getFilter',
        }),
    },
    watch: {
        getParamsQuery: {
            handler() {
                this.handleDebounce();
            },
            deep: true,
        },
        selectedItemType(newVal) {
            if (newVal === '') {
                this.filteredItemType = {};
            }
        },
    },

    created() {
        this.debouncedFetchProducts = this.debounce(
            this.handleSearchByName,
            2000,
        );
    },

    methods: {
        handleFilterItemType() {
            if (this.selectedItemGroup) {
                this.filteredItemType = this.getSubCategories();
                this.$store.commit(this.commitParams, {
                    itemGroup: this.selectedItemGroup,
                });
            }

            if (!this.getSubCategories()) {
                this.$store.commit(this.commitParams, {
                    itemType: undefined,
                });
            }
        },

        getSubCategories() {
            return this.filters.find(
                (item) => item.itemGroup === this.selectedItemGroup,
            ).type;
        },

        handleSelectedItemType() {
            if (this.getSubCategories()) {
                this.$store.commit(this.commitParams, {
                    itemType: this.selectedItemType,
                });
            }
        },

        handleSortByPrice() {
            this.$store.commit(this.commitParams, {
                sortBy: this.sortyBySelected,
            });
        },

        handleSearchByName() {
            if (this.searchByName) {
                this.$store.commit(this.commitParams, {
                    itemName: this.searchByName,
                });
            } else {
                this.$store.commit(this.commitParams, {
                    itemName: undefined,
                });
            }
        },

        handleEnter() {
            console.log('search name', this.searchByName);
            this.handleSearchByName();
        },

        clearParam() {
            this.selectedItemGroup = '';
            this.selectedItemType = '';
            this.sortItemBy = '';
            this.searchByName = '';
            this.sortyBySelected = '';
            this.filteredItemType = {};
        },

        async handleClearFilter() {
            this.clearParam();
            await this.$store.commit(this.commitParams, {
                itemName: undefined,
                sortBy: undefined,
                itemGroup: undefined,
                itemType: undefined,
            });
        },

        handleDebounce: throttle(async function () {
            await this.$store.dispatch(this.dispatchGetList);
        }, 1000),

        // debounce(func, timeout = 500) {
        //     let timer;
        //     return function (...args) {
        //         clearTimeout(timer);
        //         timer = setTimeout(() => {
        //             func.apply(this, args);
        //         }, timeout);
        //     };
        // },
    },

    mounted() {
        this.$store.dispatch(DISPATCH_GET_FILTER);
    },
    beforeDestroy() {
      this.handleClearFilter()
    },
};
</script>
<style lang="scss" scoped>
$modal-bg-color: rgba(39, 31, 31, 0.5);
$white-color: #fff;
$border-color: #ddd;
$text-color: #333;
$secondary-text-color: #999;
$primary-color: #0087cc;
$primary-hover-color: #0056b3;
$sucess-color: #10cb48;

.filter-container {
    h2 {
        font-size: 24px;
    }

    display: flex;
    gap: 8px;
    flex-direction: column;

    .filter-function,
    .select {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
    }

    .primary-search {
        display: flex;
        align-items: center;
        border: 1px solid #7dc1eb;
        border-radius: 100px;
        padding: 12px 18px;
        background-color: #f7fafe;
        width: 30%;
        height: 48px;

        .search-icon {
            color: #7dc1eb;
            font-size: 18px;
            margin-right: 10px;
        }

        .search-input {
            border: none;
            outline: none;
            font-size: 16px;
            flex: 1;
            background: none;
            color: #333;

            &::placeholder {
                color: #ccc;
            }
        }
    }

    .secondary-search {
        display: flex;
        align-items: center;
        border: 1px solid #aaaaaa6b;
        border-radius: 8px;
        padding: 6px 12px;
        width: 30%;
        height: 35px;
        background-color: #fff;
        .search-icon {
            color: #8e8e93;
            font-size: 14px;
            margin-right: 10px;
        }

        .search-input {
            border: none;
            outline: none;
            font-size: 14px;
            flex: 1;
            background: none;
            color: #333;

            &::placeholder {
                color: #ccc;
            }
        }
    }

    #category,
    #sortby,
    #price {
        padding: 6px 32px 6px 12px;
        border: 1px solid #aaaaaa6b;
        border-radius: 8px;
        width: 180px;
        font-size: 14px;
        font-weight: 600;
        color: #8e8e93;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: white;
        background-image: url('../icons/chevron-down.png');
        background-repeat: no-repeat;
        background-size: 24px;
        background-position: right 8px center;
        text-transform: capitalize;
    }

    @media (max-width: 768px) {
        .filter-function {
            flex-direction: column;
        }

        .primary-search {
            width: 100%;
        }

        .secondary-search {
            width: 100%;
        }

        .select {
            width: 100%;
            display: grid;
            grid-template-columns: 33% 33% 33%;
            gap: 3px;
        }

        #category,
        #sortby,
        #price {
            padding: 10px 16px;
            border: 1px solid #aaaaaa6b;
            border-radius: 8px;
            width: 100%;
            font-size: 12px;
            font-weight: 600;
            color: #8e8e93;
            background: url('../icons/arrowDownIcon.png') no-repeat right;
            background-size: 18px;
            background-position-x: 125px;
            z-index: 20;
        }
    }
}

.dash-line {
    width: 100%;
    height: 2px;
    border: 1px dashed #333;
}
</style>
