<template>
    <div v-if="open" class="modal" @click.self="closeModal">
        <div class="modal-content">
            <span class="close" @click="closeModal">
                <CloseIcon color="#333" />
            </span>
            <div class="modal-header">
                <img
                    :src="product.itemData.photoUrl"
                    alt="Product Image"
                    class="product-thumbnail"
                />
                <div class="wrapper-info">
                    <h2 class="product-title">
                        {{ product.itemData.name }}
                        <span v-if="product.itemData.nftNumber">
                            -
                            {{ product.itemData.nftNumber }}
                        </span>
                    </h2>
                    <p class="seller-info">
                        {{ $t('seller') }}: #{{ product.userId }}
                    </p>
                </div>
            </div>
            <div class="modal-body">
                <div class="price-section">
                    <p>{{ $t('price') }}:</p>
                    <div class="price">
                        <div>
                            <span>{{
                                product.price.xinCoin.toLocaleString('en-US', {
                                    maximumFractionDigits: 4,
                                })
                            }}</span>
                            <img
                                src="@/assets/images/logo/xin.png"
                                alt="XIN Icon"
                            />
                        </div>
                        <div>
                            <span>{{
                                product.price.USDT.toLocaleString('en-US', {
                                    maximumFractionDigits: 4,
                                })
                            }}</span>
                            <img
                                src="@/assets/images/logo/usdt.png"
                                alt="USDT Icon"
                            />
                        </div>
                    </div>
                </div>
                <div class="product-fee">
                    <p>
                        {{ $t('fee') }} ({{ product.fee.marketFeeByPercent }}%):
                    </p>
                    <div class="product-fee-price">
                        <div>
                            <span
                                >+{{
                                    product.fee.xinCoin.toLocaleString(
                                        'en-US',
                                        {
                                            maximumFractionDigits: 4,
                                        },
                                    )
                                }}</span
                            >
                            <img
                                src="@/assets/images/logo/xin.png"
                                alt="XIN Icon"
                            />
                        </div>
                        <div>
                            <span
                                >+{{
                                    product.fee.USDT.toLocaleString('en-US', {
                                        maximumFractionDigits: 4,
                                    })
                                }}</span
                            >
                            <img
                                src="@/assets/images/logo/usdt.png"
                                alt="USDT Icon"
                            />
                        </div>
                    </div>
                </div>
                <div class="product-total">
                    <p>{{ $t('total') }}</p>
                    <div class="product-total-price">
                        <div>
                            <span>{{
                                (
                                    Number(product.fee.xinCoin) +
                                    Number(product.price.xinCoin)
                                ).toLocaleString('en-US', {
                                    maximumFractionDigits: 4,
                                })
                            }}</span>
                            <img
                                src="@/assets/images/logo/xin.png"
                                alt="XIN Icon"
                            />
                        </div>
                        <div>
                            <span>{{
                                (
                                    Number(product.fee.USDT) +
                                    Number(product.price.USDT)
                                ).toLocaleString('en-US', {
                                    maximumFractionDigits: 4,
                                })
                            }}</span>
                            <img
                                src="@/assets/images/logo/usdt.png"
                                alt="USDT Icon"
                            />
                        </div>
                    </div>
                </div>
                <div class="payment-options">
                    <div>
                        <label :for="listCurrency.xin">
                            <img
                                src="@/assets/images/logo/xin.png"
                                alt="XIN Icon"
                            />
                            <span>{{ $t('buyWithXIN') }}</span>
                        </label>
                        <input
                            type="radio"
                            name="payment-method"
                            :id="listCurrency.xin"
                            :value="listCurrency.xin"
                            checked
                            v-model="selectedPaymentMethod"
                        />
                    </div>

                    <div>
                        <label :for="listCurrency.USTD">
                            <img
                                src="@/assets/images/logo/usdt.png"
                                alt="XIN Icon"
                            />
                            <span>{{ $t('buyWithUSDT') }}</span>
                        </label>
                        <input
                            type="radio"
                            name="payment-method"
                            :value="listCurrency.USTD"
                            v-model="selectedPaymentMethod"
                            :id="listCurrency.USTD"
                        />
                    </div>
                </div>
                <SentOTP
                    v-model="opt"
                    :handleSendOTP="sentOPT"
                    :maxlength="7"
                />
                <button class="buy-now-btn" @click="hanleBuyItem(product._id)">
                    {{ $t('buyNow') }}
                </button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import { currency } from '../const';
import CloseIcon from '../icons/CloseIcon.vue';
import SentOTP from '../share/SentOTP.vue';

export default {
    name: 'ModalPayment',
    components: {
        CloseIcon,
        SentOTP,
    },
    data() {
        return {
            selectedPaymentMethod: currency.xin,
            listCurrency: currency,
            opt: null,
            showPaymentSuccess: false,
        };
    },
    props: {
        product: {
            type: Object,
        },
        open: {
            type: Boolean,
            required: true,
        },
        closeModalParent: {
            type: Function,
        },
    },
    emits: ['update:open'],

    computed: {
        ...mapGetters({
            isBuySuccess: 'marketplaceNft/getIsBuySuccess',
        }),
        formattedTime() {
            const minutes = Math.floor(this.reSendOPT / 60);
            const seconds = this.reSendOPT % 60;
            return `${minutes}:${seconds.toString().padStart(2, '0')}`;
        },
    },

    methods: {
        closeModal() {
            this.$emit('update:open', false);
        },
        ...mapActions({
            sentOPT: 'marketplaceNft/sentOTP',
            buyItem: 'marketplaceNft/buyItem',
        }),

        async hanleBuyItem(marketItemOid) {
            await this.buyItem({
                otp: Number(this.opt),
                marketItemOid,
                currency: this.selectedPaymentMethod,
            });
            if (this.isBuySuccess) {
                await this.$store.dispatch('marketplaceNft/getProducts');
                await this.$store.dispatch('info/req_getInfo');
                this.closeModal();
                this.closeModalParent();
            }
        },
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
<style lang="scss" scoped>
$modal-bg-color: rgba(0, 0, 0, 0.5);
$white-color: #fff;
$border-color: #ddd;
$text-color: #333;
$secondary-text-color: #999;
$primary-color: #0087cc;
$primary-hover-color: #0056b3;
$error-color: red;
$gap-desktop: 10px;
$gap-mobie: 5px;
$radius-button: 72px;

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $modal-bg-color;
    z-index: 1000;

    .error-message {
        color: $error-color;
        font-size: 12px;
        margin-top: 5px;
    }

    .modal-content {
        background: $white-color;
        border-radius: 12px;
        padding: 32px;
        width: 65%;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        gap: 10px;
        .close {
            position: absolute;
            z-index: 9;
            top: 27px;
            right: 27px;
            cursor: pointer;
        }
        .modal-header {
            display: flex;
            align-items: center;
            gap: 24px;
            border-bottom: 1px solid $border-color;
            padding-bottom: 10px;

            .wrapper-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 12px;
            }

            .product-thumbnail {
                width: 86px;
                height: 86px;
                border-radius: 12px;
                object-fit: contain;
            }

            .product-title {
                font-size: 22px;
                font-weight: bold;
                color: $primary-color;
                margin: 0;
            }

            .seller-info {
                font-size: 16px;
                color: $secondary-text-color;
                margin: 0;
                text-align: start;
            }
        }

        .modal-body {
            padding: 0px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .price-section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 5px;

                .price {
                    margin-bottom: 0;

                    div {
                        gap: 5px;
                        font-size: 18px;
                        text-align: right;

                        img {
                            width: 16px;
                            height: 16px;
                            margin-left: 10px;
                            margin-bottom: 5px;
                        }

                        &:first-child {
                            span {
                                font-size: 20px;
                                font-weight: bold;
                            }
                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }

            .product-fee {
                padding: 10px 0px;
                display: flex;
                justify-content: space-between;

                .product-fee-price {
                    margin-bottom: 0;

                    div {
                        gap: 5px;
                        font-size: 18px;
                        text-align: right;

                        img {
                            width: 16px;
                            height: 16px;
                            margin-left: 10px;
                            margin-bottom: 5px;
                        }

                        &:first-child {
                            span {
                                font-size: 20px;
                            }

                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }

            .product-total {
                border-bottom: 1px solid $border-color;
                display: flex;
                justify-content: space-between;
                padding-bottom: 10px;

                p {
                    font-weight: 700;
                }

                .product-total-price {
                    margin-bottom: 0;

                    div {
                        gap: 5px;
                        font-size: 18px;
                        text-align: right;

                        img {
                            width: 16px;
                            height: 16px;
                            margin-left: 10px;
                            margin-bottom: 5px;
                        }

                        &:first-child {
                            span {
                                font-size: 20px;
                                font-weight: bolder;
                                color: $primary-color;
                            }

                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }

            .payment-options {
                border-bottom: 1px solid $border-color;
                padding-bottom: 10px;
                gap: 10px;

                div {
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    font-size: 18px;
                    cursor: pointer;

                    input[type='radio'] {
                        accent-color: $primary-color;
                        width: 24px;
                    }
                    label {
                        display: flex;
                        align-items: center;
                        margin-top: 3px;
                    }
                    label > img {
                        width: 24px;
                        height: 24px;
                        object-fit: contain;
                        margin-right: 5px;
                    }
                }
            }

            .buy-now-btn {
                width: 100%;
                padding: 10px 15px;
                background: $primary-color;
                color: $white-color;
                border: none;
                border-radius: $radius-button;
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }

    @media (max-width: 768px) {
        .modal-content {
            width: 80%;
            max-height: 90%;
            gap: 8px;

            .close {
                top: 8px;
                right: 8px;
            }

            .modal-header {
                gap: $gap-mobie;
                border-bottom: 1px solid $border-color;
                padding-bottom: 5px;

                .product-title {
                    font-size: 14px !important;
                    font-weight: bold;
                    color: $primary-color;
                    margin: 0;
                    text-align: start;
                }

                .seller-info {
                    font-size: 12px !important;
                    color: $secondary-text-color;
                    margin: 0;
                    text-align: start;
                }
            }
            .modal-body {
                gap: 10px !important;

                .price-section {
                    .price {
                        div {
                            font-size: 16px;
                            &:first-child {
                                span {
                                    font-size: 16px;
                                }
                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }

                .product-fee {
                    .product-fee-price {
                        div {
                            font-size: 16px;
                            img {
                                width: 16px;
                                height: 16px;
                            }
                            &:first-child {
                                span {
                                    font-size: 16px;
                                }
                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }

                .product-total {
                    .product-total-price {
                        div {
                            font-size: 16px;
                            &:first-child {
                                span {
                                    font-size: 16px;
                                }
                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }

                .payment-options {
                    div {
                        font-size: 16px;

                        input[type='radio'] {
                            accent-color: $primary-color;
                            width: 16px;
                        }
                        label > img {
                            width: 16px;
                            height: 16px;
                            object-fit: contain;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
</style>
