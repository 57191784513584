const axios = require('../axios.config').default;

export default {
    namespaced: true,

    state: () => ({
        nftPackage: null,
        nftNumber: null,
        esimLocal: null,
        esimRegional: null,
        esimGlobal: null,
        eSimHistory: null,
        eSimVoucher: null,
        myNFT: null,
        freeNFT: null,
        randomList: null,
    }),

    getters: {
        NFTPackage: (state) => state.nftPackage,
        NFTNumber: (state) => state.nftNumber,
        MyNFT: (state) => state.myNFT,
        EsimLocal: (state) => state.esimLocal,
        EsimRegional: (state) => state.esimRegional,
        EsimGlobal: (state) => state.esimGlobal,
        EsimHistory: (state) => state.eSimHistory,
        EsimVoucher: (state) => state.eSimVoucher,
        FreeNFT: (state) => state.freeNFT,
        RandomList: (state) => state.randomList,
    },

    actions: {
        async getNFTPackage({ commit }) {
            return axios.get('/nft/get-nft-number-setting').then((res) => {
                if (res && res.status) {
                    commit('SET_NFT_PACKAGE', res.data);
                    // console.log('NFT Package list: ', res.data);
                }
            });
        },

        async getNFTNumber({ commit }, input) {
            return axios
                .get('/nft/get-nft-number', {
                    params: {
                        page: input.page,
                        nftId: input.nftId,
                        number: input.number,
                        limit: input.limit,
                    },
                })
                .then((res) => {
                    if (res && res.status) {
                        commit('SET_NFT_NUMBER', res.data);
                        // console.log('NFT Number list: ', res.data);
                    }
                    return res;
                });
        },

        async buyNFT(context, input) {
            return axios.post('/nft/buy-nft-number', input);
        },

        async setDefaultNFT(context, input) {
            return axios.patch(`/nft/set-default/${input}`);
        },

        async getMyNFT({ commit }, input) {
            return axios
                .get('nft/get-my-nft', { params: input })
                .then((res) => {
                    if (res && res.status) {
                        commit('SET_MY_NFT', res.data);
                        // console.log('My NFT: ', res.data);
                    }
                });
        },

        async getEsimLocal({ commit }, input) {
            return axios
                .get('/esim', {
                    params: {
                        'filter[type]': input.filter.type,
                        'filter[country]': input.filter.country,
                        'page': input.page,
                        'limit': input.limit,
                        'keySearch': input.keySearch,
                    },
                })
                .then((res) => {
                    if (res && res.status) {
                        commit('SET_ESIM_LOCAL', res.data);
                        // console.log('Esim Local list: ', res.data);
                    }
                    return res;
                });
        },

        async getEsimRegional({ commit }, input) {
            return axios
                .get('/esim', {
                    params: {
                        'filter[type]': input.filter.type,
                        'filter[regional]': input.filter.regional,
                    },
                })
                .then((res) => {
                    if (res && res.status) {
                        commit('SET_ESIM_REGIONAL', res.data);
                        // console.log('Esim Regional list: ', res.data);
                    }

                    return res;
                });
        },

        async getEsimGlobal({ commit }, input) {
            return axios
                .get('/esim', {
                    params: {
                        'filter[type]': input.filter.type,
                    },
                })
                .then((res) => {
                    if (res && res.status) {
                        commit('SET_ESIM_GLOBAL', res.data);
                        // console.log('Esim Global list: ', res.data);
                    }

                    return res;
                });
        },

        async getEsimHistory({ commit }, input) {
            return axios
                .get('/esim/histories', { params: input })
                .then((res) => {
                    if (res) {
                        commit('SET_ESIM_HISTORY', res.data);
                        return res.data;
                    }
                });
        },

        async getEsimVoucher({ commit }, input) {
            return axios
                .get('/voucher/e-sim', { params: input })
                .then((res) => {
                    if (res) {
                        commit('SET_ESIM_VOUCHER', res.data);
                        return res.data;
                    }
                });
        },

        async buyEsim(context, input) {
            return axios.post('/esim', input).then((res) => {
                if (res && res.status) {
                    return res.data;
                }
            });
        },

        async getFreeNFT({ commit }) {
            return axios.get('/nft/temp').then((res) => {
                if (res && res.status) {
                    commit('SET_NFT_FREE', res.data);
                    return res.data;
                }
                return;
            });
        },

        async buyNFTFree(context, input) {
            return axios.patch('/nft/buy-nft-free', input).then((res) => {
                if (res && res.status) {
                    return res.data;
                }
                return;
            });
        },

        async getRandomNFT({ commit }, params) {
            return axios.get(`/nft/list-random/${params}`).then((res) => {
                if (res) {
                    commit('SET_RANDOM_LIST', res.data);
                }
            });
        },

        async randomNFT(context, input) {
            return axios.post('/nft/random', input);
        },

        async buyRandom(context, input) {
            return axios.post('/nft/buy-random', input);
        },

        async chooseNFT(context, input) {
            return axios.post('/nft/choose-nft', input);
        },
    },
    mutations: {
        SET_NFT_PACKAGE(state, data) {
            state.nftPackage = data;
        },

        SET_NFT_NUMBER(state, data) {
            state.nftNumber = data;
        },

        SET_MY_NFT(state, data) {
            state.myNFT = data;
        },

        SET_ESIM_LOCAL(state, data) {
            state.esimLocal = data;
        },

        SET_ESIM_REGIONAL(state, data) {
            state.esimRegional = data;
        },

        SET_ESIM_GLOBAL(state, data) {
            state.esimGlobal = data;
        },

        SET_ESIM_HISTORY(state, data) {
            state.eSimHistory = data;
        },

        SET_ESIM_VOUCHER(state, data) {
            state.eSimVoucher = data;
        },

        SET_NFT_FREE(state, data) {
            state.freeNFT = data;
        },

        SET_RANDOM_LIST(state, data) {
            state.randomList = data;
        },

        SET_RANDOM_LIST_DEFAULT(state) {
            state.randomList = [];
        },

        BUY_NFT_SUCCESS() {},
        BUY_ESIM_SUCCESS() {},
    },
};
