import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import i18n from '@/locales';
import Layout from './layouts/Master.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        redirect: { name: 'Dashboard' },
    },
    {
        path: '/',
        component: () => import('./views/ScriptView.vue'),
    },
    {
        path: '/',
        component: Layout,
        redirect: '/account',
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: 'member',
                name: 'Member',
                component: () => import('@/components/account/Member.vue'),
                meta: {
                    authRequired: true,
                    title: 'User Referral Link',
                },
            },
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: () => import('@/router/views/DashboardView.vue'),
                meta: {
                    title: 'Dashboard',
                },
            },
            {
                path: 'wallet',
                name: 'Wallet',
                component: () => import('@/router/views/WalletView.vue'),
                meta: {
                    title: 'Wallet',
                },
            },
            {
                path: 'xnode',
                name: 'XNode',
                component: () => import('@/router/views/MiningView.vue'),
                meta: {
                    title: 'XNode',
                },
                redirect: { name: 'BuyXnode' },
                children: [
                    {
                        path: 'buy',
                        name: 'BuyXnode',
                        component: () => import('@/router/views/BuyXnode.vue'),
                        meta: {
                            title: 'Shop',
                        },
                    },
                    {
                        path: 'inventory',
                        name: 'XnodeInventory',
                        component: () =>
                            import('@/router/views/XnodeInventory.vue'),
                        meta: {
                            title: 'Inventory',
                        },
                    },
                ],
            },
            {
                path: 'master',
                name: 'MasterXNode',
                component: () =>
                    import('@/router/views/MasterNodeDashboard.vue'),
                meta: {
                    title: 'Master XNode Dashboard',
                },
            },
            {
                path: 'master/about',
                name: 'AboutMasterXNode',
                component: () => import('@/router/views/AboutMasterXNode.vue'),
                meta: {
                    title: 'Master XNode',
                },
            },
            {
                path: 'account',
                name: 'Account',
                component: () => import('./views/AccountView.vue'),
                meta: {
                    authRequired: true,
                    title: 'Information Account',
                },
            },
            {
                path: 'activity',
                name: 'Activity',
                component: () => import('./views/ActivityView.vue'),
                meta: {
                    authRequired: true,
                    title: 'Activities',
                },
            },
            {
                path: 'voucher',
                name: 'Voucher',
                component: () => import('./views/VoucherView.vue'),
                meta: {
                    authRequired: true,
                    title: 'Voucher shop',
                },
                redirect: { name: 'GotIt' },
                children: [
                    {
                        path: 'gotit',
                        component: () =>
                            import('@/router/views/Voucher/GotItParent.vue'),
                        name: 'GotItWrap',
                        children: [
                            {
                                path: '/',
                                name: 'GotIt',
                                component: () =>
                                    import(
                                        '@/router/views/Voucher/GotItView.vue'
                                    ),
                            },
                            {
                                path: 'history/list',
                                name: 'GotItHistory',
                                component: () =>
                                    import(
                                        '@/router/views/Voucher/GotItHistory.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        path: '/',
                        name: 'XINVoucher',
                        component: () =>
                            import('@/router/views/Voucher/XINVoucher.vue'),
                    },
                ],
            },
            // {
            //     path: 'shop',
            //     component: () => import('./views/ShopView.vue'),
            //     meta: {
            //         authRequired: true,
            //         title: 'Voucher Shop',
            //     },
            //     children: [
            //         {
            //             path: '/',
            //             name: 'Shop',
            //             component: () =>
            //                 import('@/router/views/Shop/VoucherShop.vue'),
            //         },
            //         {
            //             path: '/create-voucher',
            //             name: 'create-voucher',
            //             component: () =>
            //                 import('@/router/views/Shop/CreateVoucherWrap.vue'),
            //             name: 'CreateVoucher',
            //         },
            //     ],
            // },
            {
                path: 'utility',
                name: 'Utilities',
                component: () => import('./layouts/BlankView.vue'),
                meta: {
                    authRequired: true,
                    title: 'Utilities',
                },
                redirect: {
                    name: 'UtilityOptions',
                },
                children: [
                    {
                        path: '',
                        name: 'UtilityOptions',
                        component: () => import('./views/UtilitiesView.vue'),
                    },
                    {
                        path: 'electric',
                        name: 'ElectricPayment',
                        component: () => import('./views/Utility/Payment.vue'),
                        // import('./views/Utility/ElectricPayment.vue'),
                    },
                    {
                        path: 'internet',
                        name: 'InternetPayment',
                        component: () => import('./views/Utility/Payment.vue'),
                        // import('./views/Utility/InternetPayment.vue'),
                    },
                    {
                        path: 'electric/:id',
                        name: 'ElectricBill',
                        component: () =>
                            import('./views/Utility/ElectricDetail.vue'),
                    },
                    {
                        path: 'internet/:id',
                        name: 'InternetBill',
                        component: () =>
                            import('./views/Utility/InternetDetail.vue'),
                    },
                ],
            },
            {
                path: 'corpopackage',
                name: 'CorpoPackage',
                component: () => import('./views/CorpoView.vue'),
                meta: {
                    authRequired: true,
                    title: 'Corpo package',
                },
            },
            {
                path: 'exchange',
                name: 'P2PExchange',
                component: () => import('./views/P2PExchange.vue'),
                meta: {
                    authRequired: true,
                    title: 'P2P Exchange',
                },
            },
            {
                path: 'masternode-map',
                name: 'MasterNodeMap',
                component: () => import('./views/LocationView.vue'),
                meta: {
                    authRequired: true,
                    title: 'Masternode Map',
                },
            },
            {
                path: 'nft-esim',
                component: () => import('./views/NFTView.vue'),
                meta: {
                    authRequired: true,
                    title: 'X-NFT View',
                },
                children: [
                    {
                        path: '',
                        name: 'BuyNFT',
                        component: () =>
                            import('@/router/views/NFT/BuyNFT.vue'),
                    },
                    {
                        path: '/buy-esim',
                        name: 'BuyEsim',
                        component: () =>
                            import('@/router/views/NFT/BuyEsim.vue'),
                        redirect: { name: 'LocalEsim' },
                        children: [
                            {
                                path: 'local-esim',
                                name: 'LocalEsim',
                                component: () =>
                                    import(
                                        '@/router/views/NFT/Esim/LocalEsim.vue'
                                    ),
                                children: [
                                    {
                                        path: '',
                                        name: 'LocalEsim',
                                        component: () =>
                                            import(
                                                '@/router/views/NFT/Esim/AreaCode.vue'
                                            ),
                                    },
                                    {
                                        path: 'local-payment',
                                        name: 'LocalPayment',
                                        component: () =>
                                            import(
                                                '@/router/views/NFT/Esim/LocalPayment.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                path: 'regional-esim',
                                name: 'RegionalEsim',
                                component: () =>
                                    import(
                                        '@/router/views/NFT/Esim/RegionalEsim.vue'
                                    ),
                                children: [
                                    {
                                        path: '',
                                        name: 'RegionalEsim',
                                        component: () =>
                                            import(
                                                '@/router/views/NFT/Esim/RegionalCode.vue'
                                            ),
                                    },
                                    {
                                        path: 'regional-payment',
                                        name: 'RegionalPayment',
                                        component: () =>
                                            import(
                                                '@/router/views/NFT/Esim/RegionalPayment.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                path: 'global-esim',
                                name: 'GlobalEsim',
                                component: () =>
                                    import(
                                        '@/router/views/NFT/Esim/GlobalEsim.vue'
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'staking',
                name: 'StakingView',
                component: () => import('./views/StakingView.vue'),
                meta: {
                    authRequired: true,
                    title: 'Staking',
                },
            },
            {
                path: 'support-center',
                name: 'SupportView',
                component: () => import('./views/SupportView.vue'),
                meta: {
                    authRequired: true,
                    title: 'Support Center',
                },
            },
            {
                path: '/market-place',
                name: 'MarketplaceView',
                component: () => import('./layouts/BlankView.vue'),
                meta: {
                    title: 'Marketplace',
                },
                children: [
                    {
                        path: '',
                        name: 'MarketPlace',
                        component: () =>
                            import('./views/MarketPlace/MarketPlaceView.vue'),
                        meta: {
                            title: 'Marketplace',
                        },
                    },
                    {
                        path: '/my-market-place',
                        name: 'MyMarket',
                        component: () =>
                            import('./views/MarketPlace/MyMarketView.vue'),
                        meta: {
                            title: 'My Marketplace',
                        },
                        redirect: { name: 'Product' },
                        children: [
                            {
                                path: 'product',
                                alias: '',
                                name: 'MyProduct',
                                component: () =>
                                    import('./layouts/BlankView.vue'),
                                redirect: { name: 'Product' },
                                children: [
                                    {
                                        path: 'list',
                                        name: 'Product',
                                        component: () =>
                                            import(
                                                './views/MarketPlace/MyMarket/Product.vue'
                                            ),
                                        meta: {
                                            title: 'My Product',
                                        },
                                    },
                                    {
                                        path: 'add',
                                        name: 'AddProduct',
                                        component: () =>
                                            import(
                                                './views/MarketPlace/MyMarket/AddProductView.vue'
                                            ),
                                        meta: {
                                            title: 'Add Product',
                                        },
                                    },
                                ],
                            },

                            {
                                path: 'history',
                                name: 'MarketHistory',
                                component: () =>
                                    import(
                                        './views/MarketPlace/MyMarket/History.vue'
                                    ),
                                meta: {
                                    title: 'My History',
                                },
                            },
                            // {
                            //     path: 'balance',
                            //     name: 'Balance',
                            //     component: () =>
                            //         import(
                            //             './views/MarketPlace/MyMarket/Balance.vue'
                            //         ),
                            //     meta: {
                            //         title: 'My Balance',
                            //     },
                            // },
                        ],
                    },
                    {
                        path: '/purchased-products',
                        name: 'PurchasedProducts',
                        component: () =>
                            import(
                                './views/MarketPlace/PurchasedProductsView.vue'
                            ),
                        meta: {
                            title: 'Purchased Products',
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/',
        component: () => import('./layouts/Authentication.vue'),
        meta: {
            title: 'Login Marketplace',
        },
        children: [
            {
                path: '/login',
                name: 'Login',
                component: () => import('./views/Auth/Login.vue'),
                meta: {
                    title: 'Login Marketplace',
                },
            },
            {
                path: '/register',
                name: 'Register',
                component: () => import('./views/Auth/Register.vue'),
                meta: {
                    title: 'Register Marketplace',
                },
            },
            {
                path: '/forgot',
                name: 'Forgot',
                component: () => import('./views/Auth/Forgot.vue'),
                meta: {
                    title: 'Forgot Marketplace',
                },
            },
            {
                path: '/active-mail',
                name: 'Active-mail',
                component: () => import('./views/Auth/ActiveEmail.vue'),
                meta: {
                    title: 'Active Email Marketplace',
                },
            },
            {
                path: '/register-wallet',
                name: 'Register-wallet',
                component: () => import('./views/Auth/RegisterWallet.vue'),
                meta: {
                    title: 'Register Wallet Marketplace',
                },
            },
        ],
    },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
    linkActiveClass: 'active',
    linkExactActiveClass: 'active-children',
});
router.beforeEach((routeTo, routeFrom, next) => {
    const publicPages = ['/login', '/register', '/forgot', '/resend'];
    const authpage = publicPages.includes(routeTo.path);
    const isLogin = store.getters['auth/loggedIn'];
    if (routeTo.matched.some((m) => m.meta.comingSoon)) {
        store.commit('core/INFO_MESSAGE', i18n.t('comingSoon'), {
            root: true,
        });
        if (routeFrom) {
            next(routeFrom);
        } else {
            next({ name: 'Dashboard' });
        }
        return;
    }
    if (routeTo.matched.some((m) => m.meta.authRequired)) {
        if (isLogin) {
            next();
        } else {
            next({ name: 'Login', query: { redirect: routeTo.fullPath } });
        }
    } else if (isLogin) {
        if (authpage) {
            next({ name: 'Account' });
        } else {
            next();
        }
    } else {
        next();
    }
});
export default router;
