<template>
    <div
        class="toggler"
        v-b-tooltip.hover
        :title="$t(isActice ? 'active' : 'inactive')"
    >
        <input
            id="toggler-1"
            name="toggler-1"
            type="checkbox"
            :checked="isActice"
        />
        <label for=" toggler-1" @click="() => onClick()">
            <svg
                class="toggler-on"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
            >
                <polyline
                    class="path check"
                    points="100.2,40.2 51.5,88.8 29.8,67.5"
                ></polyline>
            </svg>
            <svg
                class="toggler-off"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
            >
                <line
                    class="path line"
                    x1="34.4"
                    y1="34.4"
                    x2="95.8"
                    y2="95.8"
                ></line>
                <line
                    class="path line"
                    x1="95.8"
                    y1="34.4"
                    x2="34.4"
                    y2="95.8"
                ></line>
            </svg>
        </label>
    </div>
</template>
<script>
export default {
    name: 'SwitchActive',
    props: {
        isActice: Boolean,
        onClick: Function,
    },
};
</script>
<style scoped>
/* From Uiverse.io by mobinkakei */

.toggler input {
    display: none;
}

.toggler label {
    display: block;
    position: relative;
    width: 60px;
    height: 27px;
    border-radius: 36px;
    background: #e4e8e8;
    cursor: pointer;
}

.toggler label::after {
    display: block;
    border-radius: 100%;
    background: linear-gradient(to right, #b81717, #e99191);
    content: '';
    animation-name: toggler-size;
    animation-duration: 0.15s;
    animation-timing-function: ease-out;
    animation-direction: forwards;
    animation-iteration-count: 1;
    animation-play-state: running;
}

.toggler label::after,
.toggler label .toggler-on,
.toggler label .toggler-off {
    position: absolute;
    top: 50%;
    left: 25%;
    width: 26px;
    height: 26px;
    transform: translateY(-50%) translateX(-50%);
    transition: left 0.15s ease-in-out, background-color 0.2s ease-out,
        width 0.15s ease-in-out, height 0.15s ease-in-out,
        opacity 0.15s ease-in-out;
}

.toggler input:checked + label::after,
.toggler input:checked + label .toggler-on,
.toggler input:checked + label .toggler-off {
    left: 75%;
}

.toggler input:checked + label::after {
    background: #0087cc;
    animation-name: toggler-size2;
}

.toggler .toggler-on,
.toggler .toggler-off {
    opacity: 1;
    z-index: 2;
}

.toggler input:checked + label .toggler-off,
.toggler input:not(:checked) + label .toggler-on {
    width: 0;
    height: 0;
    opacity: 0;
}

.toggler input:disabled {
    cursor: not-allowed;
}

.toggler .path {
    fill: none;
    stroke: #fefefe;
    stroke-width: 7px;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

@keyframes toggler-size {
    0%,
    100% {
        width: 20px;
        height: 20px;
    }

    50% {
        width: 18px;
        height: 18px;
    }
}

@keyframes toggler-size2 {
    0%,
    100% {
        width: 20px;
        height: 20px;
    }

    50% {
        width: 18px;
        height: 18px;
    }
}
</style>
