<template>
    <div>
        <div v-if="open" class="modal" @click.self="closeModal">
            <div class="modal-content">
                <span class="close" @click="closeModal">
                    <CloseIcon color="#333" />
                </span>
                <div class="modal-body">
                    <div class="product-image">
                        <img :src="product.itemData.photoUrl" alt="Motorbike" />
                    </div>
                    <div class="product-details">
                        <div class="seller">
                            <img
                                src="@/assets/images/mockup/avatar.png"
                                alt="Avatar"
                                class="avatar"
                            />
                            <div>
                                <p class="seller-id">
                                    {{ product.userId }}
                                </p>
                                <p v-if="product.userData" class="seller-name">
                                    {{ product.userData.email }}
                                </p>
                            </div>
                        </div>
                        <h2 class="product-title">
                            {{ product.itemData.name }}
                            <span v-if="product.itemData.nftNumber">{{
                                `- ${product.itemData.nftNumber}`
                            }}</span>
                        </h2>
                        <div class="product-price">
                            <p class="price-label">{{ $t('price') }}:</p>
                            <div class="price-info">
                                <div class="price">
                                    <span>{{
                                        product.price.xinCoin.toLocaleString(
                                            'en-US',
                                            {
                                                maximumFractionDigits: 4,
                                            },
                                        )
                                    }}</span>
                                    <img
                                        src="@/assets/images/logo/xin.png"
                                        alt="Currency Icon"
                                    />
                                </div>
                                <div class="price">
                                    <span>{{
                                        product.price.USDT.toLocaleString(
                                            'en-US',
                                            {
                                                maximumFractionDigits: 4,
                                            },
                                        )
                                    }}</span>
                                    <img
                                        src="@/assets/images/logo/usdt.png"
                                        alt="Currency Icon"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="product-fee">
                            <span>{{ $t('fee') }}:</span>
                            <span>{{ product.fee.marketFeeByPercent }} %</span>
                        </div>

                        <button
                            v-if="product.userId !== info.refId"
                            class="buy-now"
                            @click="openModalPayment(product)"
                        >
                            {{ $t('buyNow') }}
                        </button>
                        <div>
                            <div
                                class="additional-info"
                                v-if="product.itemData.nodeExpired"
                            >
                                <p>{{ $t('lifeTime') }}:</p>
                                <p>
                                    {{
                                        getLifeTime(
                                            product.itemData.nodeExpired,
                                        )
                                    }}
                                </p>
                            </div>
                            <div
                                class="additional-info"
                                v-if="product.itemData.nodePerformance"
                            >
                                <p>{{ $t('miningCapacity') }}:</p>
                                <p>
                                    {{ product.itemData.nodePerformance }} Hz/h
                                </p>
                            </div>
                            <div v-if="product.itemData.content">
                                <ul class="content-list">
                                    <li
                                        v-for="(item, key) in product.itemData
                                            .content"
                                        :key="key"
                                    >
                                        {{ item.des }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal -->
            <ModalPayment
                :open="showModalPayment"
                :product="infoProductPayment"
                @update:open="showModalPayment = $event"
                :closeModalParent="closeModal"
            />
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import ModalPayment from './ModalPayment.vue';
import CloseIcon from '../icons/CloseIcon.vue';

export default {
    name: 'ModalInfoProduct',

    components: { ModalPayment, CloseIcon },

    props: {
        product: {
            type: Object,
        },
        open: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            showModalPayment: false,
            infoProductPayment: null,
        };
    },

    computed: {
        ...mapGetters({
            info: 'info/UserInfo',
        }),
    },

    emits: ['update:open'],

    methods: {
        closeModal() {
            this.$emit('update:open', false);
        },
        openModalPayment(product) {
            this.infoProductPayment = product;
            this.showModalPayment = true;
        },
        getLifeTime(nodeExpired) {
            let time = null;

            const expiredDate = moment(nodeExpired);
            const currentDate = moment();

            const diffInDays = expiredDate.diff(currentDate, 'days');

            const daysInMonth = expiredDate.daysInMonth();

            const decimalPartOfMonth = diffInDays / daysInMonth;

            if (decimalPartOfMonth > 1) {
                time = `${Math.round(decimalPartOfMonth)} ${this.$t('months')}`;
            } else {
                time = `${diffInDays} ${this.$t('days')}`;
            }
            return time;
        },
    },
};
</script>
<style lang="scss" scoped>
$modal-bg-color: rgba(0, 0, 0, 0.5);
$white-color: #fff;
$border-color: #ddd;
$text-color: #333;
$secondary-text-color: #999;
$primary-color: #0087cc;
$primary-hover-color: #0056b3;
$gap-desktop: 10px;
$gap-mobie: 5px;
$radius: 20px;
$radius-button: 72px;

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $modal-bg-color;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background: $white-color;
    border-radius: $radius;
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .modal-left {
        display: flex;
        align-items: center;
        gap: $gap-desktop;
    }

    .modal-body {
        display: flex;
        gap: 20px;
        padding: 32px;
        border-radius: $radius;
    }

    .product-image {
        width: 320px;
        display: flex;
        background: linear-gradient(209.87deg, #27abf2 1.29%, #070664 104.28%),
            no-repeat;
        border-radius: $radius;

        img {
            height: 100%;
            border-radius: 16px;
            margin: auto;
            object-fit: scale-down;
            max-width: 90%;
        }
    }

    .product-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: $gap-desktop;

        .seller {
            display: flex;
            gap: 8px;

            .avatar {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 1px solid $primary-color;
            }

            .seller-id {
                font-size: 14px;
                color: gray;
                margin-bottom: 0px;
            }

            .seller-name {
                font-size: 20px;
                font-weight: bold;
                color: $text-color;
                margin-bottom: 0px;
            }
        }
    }

    .product-title {
        font-size: 28px;
        line-height: 40px;
        font-weight: bold;
        color: $primary-color;
    }
    .product-price {
        display: flex;
        justify-content: space-between;
        font-size: 24px;
        .price-label {
            color: $text-color;
            margin-bottom: 0px;
        }

        .price-info {
            display: flex;
            flex-direction: column;
            gap: $gap-desktop;
        }

        .price {
            display: flex;
            align-items: center;
            gap: $gap-mobie;
            justify-content: end;
            font-weight: 600;
            img {
                width: 24px;
                height: 24px;
            }

            &:last-child {
                justify-content: end;
                font-size: 20px;
                font-weight: 400;
            }
        }
    }

    .product-fee {
        display: flex;
        justify-content: space-between;
        font-size: 24px;
    }

    .buy-now {
        padding: 10px 15px;
        background: $primary-color;
        color: $white-color;
        border: none;
        border-radius: $radius-button;
        font-size: 16px;
        cursor: pointer;
        width: 70%;
        align-self: center;
    }

    .additional-info {
        display: flex;
        justify-content: space-between;
        p {
            font-size: 18px;
            color: $text-color;
            margin-bottom: 0px;
            text-transform: capitalize;
            font-weight: 500;
        }
        p:last-child {
            color: $primary-color;
            font-weight: 600;
        }
    }

    .close {
        position: absolute;
        z-index: 9;
        top: 27px;
        right: 27px;
        cursor: pointer;
    }

    .content-list {
        font-size: 16px;
        li {
            margin-bottom: 3px;
        }
    }
}

@media (max-width: 768px) {
    .modal-content {
        width: 80%;
        max-height: 90%;
        gap: 8px;

        .close {
            top: 5px;
            right: 5px;
        }
    }

    .modal-body {
        flex-direction: column;
        padding: 20px !important;

        .product-image {
            width: 100%;
            min-height: 150px;
            max-height: 150px;

            img {
                width: 100%;
                min-height: 120px;
                max-height: 120px;
                border-radius: 8px;
                object-fit: contain;
            }
        }
        .product-details {
            gap: $gap-mobie;

            .product-title {
                font-size: 15px;
                margin-bottom: 0px;
            }
            .product-price {
                font-size: 14px;

                .price {
                    font-size: 14px;
                }
            }
            .product-fee {
                font-size: 14px;
            }

            .seller {
                .seller-name {
                    font-size: 11px;
                }
            }
        }
        .buy-now {
            margin-top: 5px;
            width: 100%;
        }
        .content-list {
            font-size: 12px;
            padding: 0px 10px;
        }
    }
}
</style>
