<script>
import { mapGetters } from 'vuex';
import { orderBuy, orderSell } from '@/store/modules/exchange';

export default {
    props: {
        isBuy: {
            type: Boolean,
            default: true,
        },
        hiddenHeader: {
            type: Boolean,
            default: false,
        },
        coin: {
            type: Object,
            default: null,
        },
        tabActive: {
            type: String,
            default: '',
        },
        itemPick: {
            type: Object,
            default: null,
        },
    },
    name: 'ExchangeComponent',
    data() {
        return {
            orderActive: 'isBuy',
            infoSell: {
                amount: 0,
                price: 0.0,
            },
            infoBuy: {
                amount: 0,
                price: 0.0,
            },
        };
    },
    watch: {
        tabActive: {
            handler(newVal) {
                if (newVal === 'isBuy' || newVal === 'isSell') {
                    this.orderActive = newVal;
                }
            },
        },
        itemPick: {
            handler(newVal) {
                this.calculatorAmount({
                    type: newVal.type,
                    amount: newVal.total,
                    totalPrice: newVal.totalPrice,
                    price: newVal.price,
                });
            },
        },
    },
    computed: {
        ...mapGetters({
            // BalanceSystem: 'sbox/BalanceUser2',
            UserInfo: 'info/UserInfo',
        }),
        TypeAction() {
            let type = {
                sell: 'xinCoin',
                buy: 'USDT',
            };
            if (this.coin && this.coin.name.length > 1) {
                const { name } = this.coin;
                const arrName = name.split('/');
                type = {
                    sell: arrName[0],
                    buy: arrName[1],
                };
            }
            return type;
        },
        BalanceSystem() {
            return {
                xinCoin: this.UserInfo.xinCoin,
                USDT: this.UserInfo.USDT,
            };
        },
    },
    methods: {
        async onChoseAmount(type, percent, coin) {
            const balance = this.BalanceSystem[coin];
            const balanceSelect = this.BalanceSystem[coin];
            if (type === 'Sell') {
                if (this.infoSell.price <= 0) {
                    this.$toastr.e(
                        'Please enter the price before chose percentage or sell',
                        'Oops!',
                    );
                    return;
                }
                this.infoSell.amount = balance * percent;
            } else {
                if (this.infoBuy.price <= 0) {
                    this.$toastr.e(
                        'Please enter the price before chose percentage or buy',
                        'Oops!',
                    );
                    return;
                }
                this.infoBuy.amount = parseInt(
                    (balanceSelect / this.infoBuy.price) * percent,
                    10,
                );
            }
        },
        async onBuySell(type) {
            // if (1 !== 2) {
            //     this.$toastr.w(
            //         'Energy Trading Is ComingSoon, Please Come Back Later',
            //         'ComingSoon',
            //     );
            //     return;
            // }
            if (!type) {
                this.$toastr.e('Error', 'Please try again');
            }

            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('p2pExchange');

            if (type === 'Sell') {
                const { amount, price } = this.infoSell;
                if (
                    !amount ||
                    this.isNaN(amount) ||
                    !price ||
                    this.isNaN(price)
                ) {
                    this.$toastr.e('Error', 'Please try again');
                    return;
                }

                await orderSell({
                    idCoin: this.coin.id,
                    amount,
                    price,
                    token
                });
            } else {
                const { amount, price } = this.infoBuy;

                if (
                    !amount ||
                    this.isNaN(amount) ||
                    !price ||
                    this.isNaN(price)
                ) {
                    this.$toastr.e('Error', 'Please try again');
                    return;
                }

                await orderBuy({
                    idCoin: this.coin.id,
                    amount,
                    price,
                    token
                });
            }
            this.$emit('getListTrading', true);
            this.$store.dispatch('info/req_getInfo');
        },
        calculatorAmount({ price, amount, totalPrice, type }) {
            const { USDT } = this.BalanceSystem;
            const ChosenCoin = this.BalanceSystem[this.coin.item];
            if (type === 'buy') {
                this.infoBuy.price = price;
                if (USDT >= totalPrice) {
                    this.infoBuy.amount = amount;
                } else {
                    this.infoBuy.amount = parseInt(USDT / price, 10);
                }
            }
            if (type === 'sell') {
                this.infoSell.price = price;
                if (ChosenCoin >= amount) {
                    this.infoSell.amount = amount;
                } else {
                    this.infoSell.amount = ChosenCoin;
                }
            }

            //     if (type === 'sell') {
            //         this.infoSell.amount = amount;
            //     } else {
            //         this.infoBuy.amount = amount;
            //     }
            // } else if (type === 'sell') {
            //     this.infoSell.amount = parseInt(HBG / price - 1, 10);
            // } else {
            //     this.infoBuy.amount = parseInt(HBG / price - 1, 10);
            // }

            // if (type === 'sell') {
            //     this.infoSell.price = price;
            // } else {
            //     this.infoBuy.price = price;
            // }
        },
    },
    mounted() {
        if (this.tabActive === 'isBuy' || this.tabActive === 'isSell') {
            this.orderActive = this.tabActive;
        }
        if (this.itemPick.type) {
            const { type, total, totalPrice, price } = this.itemPick;
            this.calculatorAmount({
                type,
                amount: total,
                totalPrice,
                price,
            });
        }
    },
};
</script>

<template>
    <div class="container-trade">
        <div class="trade-header">
            <b-button
                class="trade-header-item"
                variant="none"
                :class="{ activeBuy: orderActive == 'isBuy' }"
                @click="orderActive = 'isBuy'"
            >
                Buy
            </b-button>

            <b-button
                class="trade-header-item"
                variant="none"
                :class="{ activeSell: orderActive == 'isSell' }"
                @click="orderActive = 'isSell'"
            >
                Sell
            </b-button>
        </div>

        <div class="trade-body">
            <form class="trade-body-buy" v-if="orderActive == 'isBuy'" @submit.prevent="onBuySell('Buy')">
                <div class="form-trade">
                    <label for="usdt">Price</label>
                    <div class="input-amount">
                        <b-form-input
                            id="usdt"
                            class="form-control"
                            :placeholder="`Price ${TypeAction.buy}`"
                            v-model="infoBuy.price"
                            type="number"
                            step="any"
                            @focus="$event.target.select()"
                            required
                        />
                        <div class="icon">
                            <img
                                src="@/assets/images/logo/icon_usdt.png"
                                alt="icon_usdt"
                            />
                            <p>USDT</p>
                        </div>
                    </div>
                </div>
                <div class="form-trade">
                    <label for="xin">Amount</label>
                    <div class="input-amount">
                        <b-form-input
                            id="xin"
                            class="form-control"
                            :placeholder="`Amount ${TypeAction.sell}`"
                            v-model="infoBuy.amount"
                            type="number"
                            step="any"
                            @focus="$event.target.select()"
                            required
                        />
                        <div class="icon">
                            <img
                                src="@/assets/images/logo/xin.png"
                                alt="icon_xin"
                            />
                            <p>XIN</p>
                        </div>
                    </div>
                </div>
                <div class="suggest-trade">
                    <b-button
                        @click="onChoseAmount('Buy', 0.25, TypeAction.buy)"
                        variant="none"
                        class="suggest-trade-box"
                        type="button"
                    >
                        25%
                    </b-button>
                    <b-button
                        @click="onChoseAmount('Buy', 0.5, TypeAction.buy)"
                        variant="none"
                        class="suggest-trade-box"
                        type="button"
                    >
                        50%
                    </b-button>
                    <b-button
                        @click="onChoseAmount('Buy', 0.75, TypeAction.buy)"
                        variant="none"
                        class="suggest-trade-box"
                        type="button"
                    >
                        75%
                    </b-button>
                    <b-button
                        @click="onChoseAmount('Buy', 1, TypeAction.buy)"
                        variant="none"
                        class="suggest-trade-box"
                        type="button"
                    >
                        100%
                    </b-button>
                </div>
                <div class="form-trade mt-3">
                    <label for="usdt">Total</label>
                    <div class="input-amount">
                        <b-form-input
                            type="text"
                            readonly
                            :value="
                                TruncateToDecimals2(
                                    infoBuy.amount * infoBuy.price,
                                    '',
                                    4,
                                )
                            "
                        ></b-form-input>
                        <div class="icon">
                            <img
                                src="@/assets/images/logo/icon_usdt.png"
                                alt="icon_usdt"
                            />
                            <p>USDT</p>
                        </div>
                    </div>
                </div>
                <div
                    class="form-trade d-flex justify-content-center align-items-center"
                >
                    <b-button
                        variant="none"
                        class="button-trade buy"
                        :disabled="infoBuy.price <= 0 || infoBuy.amount <= 0"
                        type="submit"
                    >
                        Buy
                    </b-button>
                </div>
            </form>

            <form class="trade-body-sell" v-if="orderActive == 'isSell'" @submit.prevent="onBuySell('Sell')">
                <div class="form-trade">
                    <label for="xin">Price</label>
                    <div class="input-amount">
                        <b-form-input
                            id="usdt_sell"
                            class="form-control"
                            :placeholder="`Price ${TypeAction.sell}`"
                            v-model="infoSell.price"
                            type="number"
                            step="any"
                            @focus="$event.target.select()"
                            required
                        />
                        <div class="icon">
                            <img
                                src="@/assets/images/logo/icon_usdt.png"
                                alt="icon_usdt"
                            />
                            <p>USDT</p>
                        </div>
                    </div>
                </div>
                <div class="form-trade">
                    <label for="xin">Amount</label>
                    <div class="input-amount">
                        <b-form-input
                            class="form-control"
                            :placeholder="`Amount ${TypeAction.sell}`"
                            v-model="infoSell.amount"
                            type="number"
                            step="any"
                            @focus="$event.target.select()"
                            required
                        />
                        <div class="icon">
                            <img
                                src="@/assets/images/logo/xin.png"
                                alt="icon_xin"
                            />
                            <p>XIN</p>
                        </div>
                    </div>
                </div>
                <div class="suggest-trade">
                    <b-button
                        @click="onChoseAmount('Sell', 0.25, TypeAction.sell)"
                        variant="none"
                        class="suggest-trade-box"
                        type="button"
                    >
                        25%
                    </b-button>
                    <b-button
                        @click="onChoseAmount('Sell', 0.5, TypeAction.sell)"
                        variant="none"
                        class="suggest-trade-box"
                        type="button"
                    >
                        50%
                    </b-button>
                    <b-button
                        @click="onChoseAmount('Sell', 0.75, TypeAction.sell)"
                        variant="none"
                        class="suggest-trade-box"
                        type="button"
                    >
                        75%
                    </b-button>
                    <b-button
                        @click="onChoseAmount('Sell', 1, TypeAction.sell)"
                        variant="none"
                        class="suggest-trade-box"
                        type="button"
                    >
                        100%
                    </b-button>
                </div>
                <div class="form-trade mt-3">
                    <label for="usdt">Total</label>
                    <div class="input-amount">
                        <b-form-input
                            type="text"
                            readonly
                            :value="
                                TruncateToDecimals2(
                                    infoSell.amount * infoSell.price,
                                    '',
                                    4,
                                )
                            "
                        ></b-form-input>
                        <div class="icon">
                            <img
                                src="@/assets/images/logo/icon_usdt.png"
                                alt="icon_usdt"
                            />
                            <p>USDT</p>
                        </div>
                    </div>
                </div>
                <div
                    class="form-trade d-flex justify-content-center align-items-center"
                >
                    <b-button
                        variant="none"
                        class="button-trade sell"
                        :disabled="infoSell.price <= 0 || infoSell.amount <= 0"
                        type="submit"
                    >
                        Sell
                    </b-button>
                </div>
            </form>
        </div>
    </div>
</template>

<style lang="scss">
.container-trade {
    border: 1px solid #d8d8d8;
    border-radius: 24px;
    height: 100%;
    background-color: white;

    .trade {
        &-header {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            &-item {
                flex: 1;
                display: flex;
                font-weight: 600;
                box-shadow: none;
                padding: 20px 0;
                justify-content: center;
                transition: none;

                &.activeBuy {
                    border-radius: 24px 0 0 0;
                    color: white;
                    background: linear-gradient(
                        180deg,
                        #00d1ff 0%,
                        #0087c8 100%
                    );
                    border: 1px solid #0087c8;
                }

                &.activeSell {
                    border-radius: 0 24px 0 0;
                    color: white;
                    background: linear-gradient(
                        180deg,
                        #00d1ff 0%,
                        #0087c8 100%
                    );
                    border: 1px solid #0087c8;
                }
            }
        }

        &-body {
            height: calc(100% - 55px);
            margin-top: 25px;
            width: 100%;
            align-items: center;

            &-sell,
            &-buy {
                height: 100%;
                padding: 0 15px;
                width: 100%;

                .form-trade {
                    margin-bottom: 15px;

                    .input-amount {
                        position: relative;

                        input {
                            border: 0;
                            height: 48px;
                            background-color: #f7f7f7;
                            border: 1px solid #d8d8d8;
                        }

                        input[type='number'] {
                            -moz-appearance: textfield;
                        }

                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            /* display: none; <- Crashes Chrome on hover */
                            -webkit-appearance: none;
                            margin: 0;
                            /* <-- Apparently some margin are still there even though it's hidden */
                        }

                        .icon {
                            position: absolute;
                            display: flex;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 2%;
                            gap: 8px;
                            background-color: #0087cc;
                            padding: 6px 0px;
                            border-radius: 40px;
                            width: 100px;
                            justify-content: center;
                            color: white;

                            p {
                                margin: 0;
                            }

                            img {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }

                .suggest-trade {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;

                    .suggest-trade-box {
                        flex: 1;
                        background-color: #fff;
                        border: 1px solid #d8d8d8;
                        font-weight: 700;
                        transition: 0.2s;

                        &:hover {
                            border-color: #fff;
                            background-color: #0087cc;
                            color: #fff;
                        }

                        &:first-child {
                            margin-left: 0;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .button-trade {
                    width: 100%;
                    padding: 12px 0;
                    color: #fff;
                    outline: none !important;
                    font-weight: 600;
                    transition: 500ms;

                    &.sell {
                        background-color: #ff3b30;
                    }

                    &.buy {
                        background-color: #34c759;
                    }
                    &:disabled {
                        cursor: not-allowed;
                    }
                }
            }

            @media (min-width: 1400px) {
                flex-wrap: wrap;
            }
        }
    }

    @media (max-width: 480px) {
        margin-top: 1.5rem;
    }
}
</style>
