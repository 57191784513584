<template>
    <div class="header">
        <div class="container">
            <div class="nod">
                <div class="salute">{{ $t('welcome') }}!</div>
                <div class="page">
                    {{ UserInfo.email || UserInfo.phone || UserInfo.refId }} |
                    {{ UserInfo.refId }}
                </div>
            </div>
            <div class="account">
                <div class="mana">
                    <span>
                        {{ $t('mana') }}:
                        <strong v-if="typeof UserInfo.mana === 'number'">
                            <span :class="{ tooLow: UserInfo.mana < 10 }">
                                {{ UserInfo.mana === 0 ? '-' : UserInfo.mana }}
                            </span>
                            / {{ UserInfo.limitMana }}
                        </strong>
                        <strong v-else> - / {{ UserInfo.limitMana }} </strong>
                    </span>
                    <div>
                        <div
                            class="progress-bar"
                            :class="{ tooLow: UserInfo.mana < 10 }"
                        >
                            <div
                                class="progress"
                                :style="{ width: progressBarPercentage }"
                            ></div>
                        </div>
                        <svg
                            @click="$bvModal.show('mana-center')"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g filter="url(#filter0_i_4032_1551)">
                                <rect
                                    x="1"
                                    y="1"
                                    width="21"
                                    height="21"
                                    rx="10.5"
                                    fill="white"
                                />
                            </g>
                            <g clip-path="url(#clip0_4032_1551)">
                                <g filter="url(#filter1_d_4032_1551)">
                                    <path
                                        d="M17.2503 12.4565H12.4587V17.2481C12.4587 17.5023 12.3577 17.7461 12.178 17.9258C11.9982 18.1055 11.7545 18.2065 11.5003 18.2065C11.2462 18.2065 11.0024 18.1055 10.8227 17.9258C10.643 17.7461 10.542 17.5023 10.542 17.2481V12.4565H5.75033C5.49616 12.4565 5.2524 12.3555 5.07268 12.1758C4.89296 11.9961 4.79199 11.7523 4.79199 11.4981C4.79199 11.244 4.89296 11.0002 5.07268 10.8205C5.2524 10.6408 5.49616 10.5398 5.75033 10.5398H10.542V5.74813C10.542 5.49396 10.643 5.25021 10.8227 5.07048C11.0024 4.89076 11.2462 4.78979 11.5003 4.78979C11.7545 4.78979 11.9982 4.89076 12.178 5.07048C12.3577 5.25021 12.4587 5.49396 12.4587 5.74813V10.5398H17.2503C17.5045 10.5398 17.7482 10.6408 17.928 10.8205C18.1077 11.0002 18.2087 11.244 18.2087 11.4981C18.2087 11.7523 18.1077 11.9961 17.928 12.1758C17.7482 12.3555 17.5045 12.4565 17.2503 12.4565Z"
                                        fill="url(#paint0_linear_4032_1551)"
                                    />
                                </g>
                            </g>
                            <defs>
                                <filter
                                    id="filter0_i_4032_1551"
                                    x="1"
                                    y="1"
                                    width="21"
                                    height="21"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                >
                                    <feFlood
                                        flood-opacity="0"
                                        result="BackgroundImageFix"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="BackgroundImageFix"
                                        result="shape"
                                    />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="3" />
                                    <feComposite
                                        in2="hardAlpha"
                                        operator="arithmetic"
                                        k2="-1"
                                        k3="1"
                                    />
                                    <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in2="shape"
                                        result="effect1_innerShadow_4032_1551"
                                    />
                                </filter>
                                <filter
                                    id="filter1_d_4032_1551"
                                    x="-1.20801"
                                    y="-1.21021"
                                    width="25.417"
                                    height="25.4167"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                >
                                    <feFlood
                                        flood-opacity="0"
                                        result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="3" />
                                    <feComposite
                                        in2="hardAlpha"
                                        operator="out"
                                    />
                                    <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0.439216 0 0 0 0 0.933333 0 0 0 0 1 0 0 0 0.5 0"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_4032_1551"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_4032_1551"
                                        result="shape"
                                    />
                                </filter>
                                <linearGradient
                                    id="paint0_linear_4032_1551"
                                    x1="11.5003"
                                    y1="4.78979"
                                    x2="11.5003"
                                    y2="18.2065"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stop-color="#0EFFFF" />
                                    <stop offset="1" stop-color="#124DE3" />
                                </linearGradient>
                                <clipPath id="clip0_4032_1551">
                                    <rect width="23" height="23" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div class="account-balance">
                    <span class="d-none d-xl-inline">
                        {{ $t('balance') }}:
                    </span>
                    <div class="wallet-wrap">
                        <div
                            class="window special"
                            @click="dropdown = !dropdown"
                        >
                            <div
                                class="d-none d-lg-block"
                                v-b-tooltip.hover
                                :title="
                                    TruncateToDecimals2(
                                        UserInfo.xinCoin + UserInfo.bxinCoin,
                                        '',
                                        2,
                                    )
                                "
                            >
                                <span v-if="Balance">
                                    {{
                                        TruncateToDecimals2(
                                            UserInfo.xinCoin +
                                                UserInfo.bxinCoin,
                                            '',
                                            2,
                                        )
                                    }}
                                </span>
                            </div>
                            <div class="d-lg-none">
                                <span v-if="Balance">
                                    {{
                                        TruncateToDecimals2(
                                            UserInfo.xinCoin +
                                                UserInfo.bxinCoin,
                                            '',
                                            2,
                                        )
                                    }}
                                </span>
                            </div>
                            <img src="@/assets/images/logo/xin.png" />
                        </div>
                        <div
                            class="wallet-dropdown"
                            :class="{ active: dropdown }"
                        >
                            <div>
                                <div>
                                    <span v-if="Balance">
                                        {{
                                            TruncateToDecimals2(
                                                UserInfo.xinCoin +
                                                    UserInfo.bxinCoin,
                                                '',
                                                2,
                                            )
                                        }}
                                    </span>
                                </div>
                                <img src="@/assets/images/logo/xin.png" />
                            </div>
                            <div>
                                <div>
                                    <span v-if="Balance">
                                        {{
                                            TruncateToDecimals2(
                                                UserInfo.USDT + UserInfo.bUSDT,
                                                '',
                                                2,
                                            )
                                        }}
                                    </span>
                                </div>
                                <img src="@/assets/images/logo/usdt.png" />
                            </div>
                        </div>
                    </div>
                    <div class="language">
                        <span @click="$bvModal.show('language-modal')">
                            <img :src="currentFlag" alt="" />
                        </span>
                    </div>
                    <button
                        @click="$router.push({ name: 'Account' })"
                        class="avatar"
                    >
                        <img src="@/assets/images/mockup/avatar.png" />
                    </button>
                </div>
            </div>
            <div class="mobile">
                <div class="language-mobile">
                    <span @click="$bvModal.show('language-modal')">
                        <img :src="currentFlag" alt="" />
                    </span>
                </div>
                <div class="account-mobile" v-if="accountMobile">
                    <div class="mana">
                        <span>{{ $t('assets') }}: </span>
                        <div>
                            <span>
                                <strong
                                    v-if="typeof UserInfo.mana === 'number'"
                                >
                                    <span
                                        :class="{ tooLow: UserInfo.mana < 10 }"
                                    >
                                        {{
                                            UserInfo.mana === 0
                                                ? '-'
                                                : UserInfo.mana
                                        }}
                                    </span>
                                    / {{ UserInfo.limitMana }}
                                </strong>
                                <strong v-else>
                                    - / {{ UserInfo.limitMana }}
                                </strong>
                                Mana
                            </span>
                            <img
                                @click="$bvModal.show('mana-center')"
                                src="@/assets/images/icons/plus-mana.svg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="account-balance">
                        <span class="d-lg-inline"> {{ $t('balance') }}: </span>
                        <div class="wallet-wrap">
                            <div
                                class="window special"
                                @click="dropdown = !dropdown"
                            >
                                <div
                                    class="d-none d-lg-block"
                                    v-b-tooltip.hover
                                    :title="
                                        TruncateToDecimals2(
                                            UserInfo.xinCoin +
                                                UserInfo.bxinCoin,
                                            '',
                                            2,
                                        )
                                    "
                                >
                                    <span v-if="Balance">
                                        {{
                                            TruncateToDecimals2(
                                                UserInfo.xinCoin +
                                                    UserInfo.bxinCoin,
                                                '',
                                                2,
                                            )
                                        }}
                                    </span>
                                </div>
                                <div class="d-lg-none">
                                    <span v-if="Balance">
                                        {{
                                            TruncateToDecimals2(
                                                UserInfo.xinCoin +
                                                    UserInfo.bxinCoin,
                                                '',
                                                2,
                                            )
                                        }}
                                    </span>
                                </div>
                                <img
                                    @click="redirectWallet"
                                    src="@/assets/images/logo/xin.png"
                                />
                            </div>
                            <div
                                class="wallet-dropdown"
                                :class="{ active: dropdown }"
                            >
                                <div>
                                    <div>
                                        <span v-if="Balance">
                                            {{
                                                TruncateToDecimals2(
                                                    UserInfo.xinCoin +
                                                        UserInfo.bxinCoin,
                                                    '',
                                                    2,
                                                )
                                            }}
                                        </span>
                                    </div>
                                    <img src="@/assets/images/logo/xin.png" />
                                </div>
                                <div>
                                    <div>
                                        <span v-if="Balance">
                                            {{
                                                TruncateToDecimals2(
                                                    UserInfo.USDT +
                                                        UserInfo.bUSDT,
                                                    '',
                                                    2,
                                                )
                                            }}
                                        </span>
                                    </div>
                                    <img src="@/assets/images/logo/usdt.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    @click="$router.push({ name: 'Account' })"
                    class="avatar-mobile"
                >
                    <img src="@/assets/images/mockup/avatar.png" />
                </button>
                <button
                    class="mobile-show"
                    @click="accountMobile = !accountMobile"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <g fill="none" stroke="currentColor" stroke-width="1.5">
                            <circle cx="5" cy="12" r="2" />
                            <circle cx="12" cy="12" r="2" />
                            <circle cx="19" cy="12" r="2" />
                        </g>
                    </svg>
                </button>
            </div>
            <b-modal id="mana-limit-confirm" centered hide-footer hide-header>
                <svg
                    @click="$bvModal.hide('mana-limit-confirm')"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
                    />
                </svg>
                <h2>Buy Mana Limit</h2>
                <h3>Are you sure you want to increase Mana Limit ?</h3>
                <p>
                    Currently the limit is
                    <strong>{{ UserInfo.limitMana }}</strong
                    >, do you want to increase Mana Limit to
                    <strong>{{ UserInfo.limitMana + 50 }} </strong> for
                    <strong>10 USDT</strong> ?
                </p>
                <div>
                    <span @click="buyLimitMana">Confirm</span>
                    <span @click="cancelPop">Cancel</span>
                </div>
            </b-modal>
            <b-modal id="language-modal" centered hide-footer hide-header>
                <svg
                    @click="$bvModal.hide('language-modal')"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
                    />
                </svg>
                <h3>Select a language</h3>
                <div
                    class="input-container"
                    v-for="lang in AvailableLangs"
                    :key="lang.value"
                >
                    <label>
                        <strong>
                            <img :src="getLangIcon(lang.value)" alt="" />
                            {{ lang.text }}
                        </strong>
                        <span>
                            <input
                                v-model="$root.$i18n.locale"
                                @change="setLocale"
                                :value="lang.value"
                                type="radio"
                            />
                        </span>
                    </label>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MyHeader',
    data() {
        return {
            dropdown: false,
            accountMobile: false,
            manaDropdown: false,
        };
    },
    computed: {
        ...mapGetters({
            isLogin: 'auth/loggedIn',
            Balance: 'wallet/Balance',
            UserInfo: 'info/UserInfo',
            AvailableLangs: 'core/AvailableLangs',
        }),

        progressBarPercentage() {
            const percentage =
                (this.UserInfo.mana / this.UserInfo.limitMana) * 100;
            return `${percentage}%`;
        },
        currentFlag() {
            switch (this.$root.$i18n.locale) {
                case 'en':
                    return require('@/assets/images/icons/uk.png');
                case 'vi':
                    return require('@/assets/images/icons/vn.png');
                case 'ja':
                    return require('@/assets/images/icons/ja.png');
                case 'ko':
                    return require('@/assets/images/icons/ko.png');
                case 'ar':
                    return require('@/assets/images/icons/ar.png');
                case 'zh':
                    return require('@/assets/images/icons/zh.png');
                default:
                    return require('@/assets/images/icons/uk.png'); // default to English if not found
            }
        },
    },
    methods: {
        handleClickOutside(e) {
            const pop = document.querySelector('.window.special');
            const but = document.querySelector('.wallet-dropdown');
            if (
                pop &&
                but &&
                !(pop.contains(e.target) || but.contains(e.target))
            ) {
                this.dropdown = false;
            }
        },
        redirectWallet() {
            this.$router.push({ name: 'Wallet' });
        },
        buyLimitMana() {
            this.$store
                .dispatch('info/buyMana', { currency: 'USDT' })
                .then(() => {
                    this.$store.dispatch('info/req_getInfo');
                    this.$bvModal.hide('mana-limit-confirm');
                });
        },
        setLocale() {
            window.$cookies.set('lang', this.$root.$i18n.locale);
            document.documentElement.setAttribute(
                'lang',
                this.$root.$i18n.locale,
            );
        },
        cancelPop() {
            this.$bvModal.hide('mana-limit-confirm');
            this.$bvModal.show('mana-center');
        },
        goBack() {
            this.$bvModal.show('mana-center');
            this.$bvModal.hide('mana-limit-confirm');
        },
        getLangIcon(lang) {
            const icons = {
                en: require('@/assets/images/icons/uk.png'),
                vi: require('@/assets/images/icons/vn.png'),
                ja: require('@/assets/images/icons/ja.png'),
                ko: require('@/assets/images/icons/ko.png'),
                ar: require('@/assets/images/icons/ar.png'),
                zh: require('@/assets/images/icons/zh.png'),
            };
            return icons[lang] || '';
        },
    },
    mounted() {
        window.addEventListener('click', this.handleClickOutside);
        // this.$store.dispatch('info/get_downlines')
    },
    unmounted() {
        window.removeEventListener('click', this.handleClickOutside);
    },
};
</script>

<style lang="scss" scoped>
.header {
    box-shadow: 0px 6px 6px -6px #00000038;
    position: sticky;
    top: 0px;
    z-index: 2;
    background-color: #107fb9;
    position: relative;

    > .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;

        @media (max-width: 768px) {
            max-width: 100%;
        }
    }

    .nod {
        line-height: 1;
        color: #fff;

        .salute {
            font-weight: 700;
            font-size: 14px;

            @media (min-width: 768px) {
                font-size: 16px;
            }
        }

        .page {
            font-size: 12px;
            margin-top: 5px;
        }
    }

    .language-mobile {
        display: none;

        @media (max-width: 769px) {
            display: block;

            img {
                height: 30px;
            }
        }
    }

    .account {
        display: flex;
        align-items: center;
        gap: 45px;

        .mana {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 5px;
            color: #fff;
            position: relative;

            > div {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 10px;
                border-radius: 12px;

                svg {
                    cursor: pointer;
                    font-size: 18px;
                    transition: 0.2s;
                }
            }

            span {
                font-size: 18px;

                strong {
                    color: #70eeff;
                    margin-left: 20px;
                    .tooLow {
                        color: #dc2626;
                    }
                }
            }

            .progress-bar {
                width: 100%;
                background-color: #033865;
                border-radius: 4px;
                overflow: hidden;
                box-shadow: rgba(61, 217, 232, 0.35) 0px 5px 15px;

                .progress {
                    width: 0%;
                    height: 20px;
                    background: linear-gradient(#0effff, #124de3);
                    transition: width 0.3s ease;
                }
                &.tooLow {
                    .progress {
                        background: linear-gradient(#dc2626, #b91c1c);
                    }
                    box-shadow: 0px 0px 4px 0px #dc2626;
                }
            }
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    .account-balance {
        display: flex;
        align-items: center;
        gap: 15px;
        color: #fff;

        .wallet-wrap {
            position: relative;
            font-family: 700;
            font-size: 16px;
            color: #fafafa;

            img {
                width: 24px;
            }

            .wallet-dropdown {
                position: absolute;
                top: calc(100% + 10px);
                left: 0;
                width: 100%;
                background-color: #c4e3f5;
                border-radius: 12px;
                box-shadow: 1px 1px 4px 1px #00000038;

                > div {
                    padding: 4px 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 5px;

                    > div {
                        min-width: 0;

                        > span {
                            word-wrap: break-word;
                        }
                    }

                    > img {
                        flex-shrink: 0;
                    }
                }

                font-size: 14px;

                @media (min-width: 768px) {
                    font-size: 16px;
                }

                display: none;
                transition: 0.2s;

                &.active {
                    display: block;
                }

                color: #000;
            }
        }

        .window {
            background: #54a4cd;
            border-radius: 12px;
            border: 0.5px solid #fff;
            padding: 4px 10px;
            min-width: 120px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5px;

            position: relative;
            cursor: pointer;
            color: #fff;

            @media (min-width: 576px) {
                font-size: 20px;
                min-width: 160px;
            }

            > div {
                max-width: 7.5rem;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .avatar {
            width: 40px;
            aspect-ratio: 1;
            border-radius: 8px;
            border: 2px solid #25e0f9;
            padding: 2px;
            flex-shrink: 0;

            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .language {
            span {
                cursor: pointer;

                img {
                    width: 30px;
                }
            }
        }
    }

    .mobile {
        display: none;
        gap: 15px;

        .avatar-mobile {
            display: none;
            width: 40px;
            border-radius: 8px;
            border: 2px solid #25e0f9;
            padding: 2px;
            flex-shrink: 0;

            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media (max-width: 768px) {
                display: block;
            }
        }

        .account-mobile {
            position: absolute;
            right: 0;
            top: 70px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            background: #1478b0;
            padding: 10px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1019607843);
            border-radius: 12px;
            max-width: 320px;
            width: 100%;

            img {
                width: 24px;
            }

            .mana {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #fff;

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 12px;
                    border: 1px solid #d8d8d8;
                    padding: 4px 10px;
                    background: rgba(52, 160, 221, 0.2901960784);
                    min-width: 160px;
                    gap: 10px;

                    svg {
                        cursor: pointer;
                        font-size: 16px;
                    }

                    span {
                        font-size: 16px;
                        .tooLow {
                            color: #dc2626;
                        }
                    }
                }

                @media (max-width: 575px) {
                    > div {
                        min-width: 150px;
                    }
                }
            }

            .account-balance {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                font-size: 16px;
                color: #fafafa;

                span {
                    color: #fff;
                }

                .wallet-dropdown {
                    position: absolute;
                    top: calc(100% + 10px);
                    left: 0;
                    width: 100%;
                    background-color: #c4e3f5;
                    border-radius: 12px;
                    box-shadow: 1px 1px 4px 1px #00000038;

                    > div {
                        padding: 4px 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 5px;

                        > div {
                            min-width: 0;

                            > span {
                                word-wrap: break-word;
                            }
                        }

                        > img {
                            flex-shrink: 0;
                        }
                    }

                    font-size: 14px;

                    @media (min-width: 768px) {
                        font-size: 16px;
                    }

                    opacity: 0;
                    transition: 0.2s;

                    &.active {
                        opacity: 1;
                    }

                    color: #000;
                }

                .window {
                    background: #34a0dd4a;
                    border-radius: 12px;
                    padding: 4px 10px;
                    min-width: 150px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 5px;

                    position: relative;
                    cursor: pointer;
                    color: #000;

                    @media (min-width: 576px) {
                        font-size: 20px;
                        min-width: 160px;
                    }

                    > div {
                        max-width: 7.5rem;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                .avatar {
                    width: 40px;
                    aspect-ratio: 1;
                    border-radius: 8px;
                    border: 2px solid #25e0f9;
                    padding: 2px;
                    flex-shrink: 0;

                    > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .mobile-show {
            display: none;

            @media (max-width: 768px) {
                display: block;
                color: #25e0f9;

                svg {
                    width: 30px;
                }
            }
        }

        @media (max-width: 768px) {
            display: flex;
            align-items: center;
        }

        @media (max-width: 375px) {
            gap: 5px;
        }
    }
}
</style>

<style lang="scss">
#mana-limit-confirm {
    @media (min-width: 320px) {
        .modal-content {
            position: relative;
            width: 100%;
            margin: 0 auto;

            .modal-body {
                border-radius: 12px;

                h2 {
                    color: #0087cc;
                    text-align: center;
                    font-size: 30px;
                    margin-bottom: 15px;
                }

                h3 {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                }

                svg {
                    position: absolute;
                    font-size: 30px;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;
                }

                > div {
                    display: flex;
                    align-content: center;
                    justify-content: space-around;
                    gap: 15px;
                    margin-top: 1rem;

                    span {
                        cursor: pointer;
                        max-width: 200px;
                        width: 100%;
                        color: white;
                        border-radius: 72px;
                        padding: 4px 10px;
                        font-size: 18px;
                        text-transform: uppercase;
                        font-weight: 600;
                        text-align: center;
                        transition: 0.2s;

                        &:nth-child(1) {
                            background-image: linear-gradient(
                                to right,
                                #075d9b,
                                #1775b8,
                                #91c4e9,
                                #d2e9fa
                            );
                            background-position: center;
                            background-size: 200%;

                            &:hover {
                                background-position: 0%;
                            }
                        }

                        &:nth-child(2) {
                            color: #0087cc;
                            border: 1px solid #0087cc;
                        }
                    }
                }
            }
        }
    }
}

#language-modal {
    .modal-content {
        position: relative;

        .modal-body {
            border-radius: 12px;

            h3 {
                color: #0087cc;
                text-align: center;
                font-size: 30px;
                margin-bottom: 15px;
            }

            .input-container {
                label {
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                    padding: 5px;
                    border-radius: 4px;
                    transition: 0.05s;

                    &:hover {
                        background-color: rgba(0, 136, 204, 0.2);
                    }

                    input {
                        margin: 0;
                        min-width: 30px;
                        width: unset;
                        cursor: pointer;
                        height: 20px;
                    }

                    > span {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        font-weight: 500;
                    }

                    strong {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        img {
                            height: 30px;
                        }
                    }
                }
            }

            svg {
                position: absolute;
                font-size: 30px;
                top: 10px;
                right: 10px;
                cursor: pointer;
            }
        }
    }

    @media (max-width: 375px) {
        .modal-content {
            .modal-body {
                h3 {
                    margin-top: 15px;
                }
            }
        }
    }
}
</style>
