<template>
    <div class="wrap">
        <template
            v-if="
                StakePackages &&
                StakePackages.results &&
                StakePackages.results.length > 0
            "
        >
            <div
                v-for="(item, index) in StakePackages.results"
                :key="index"
                class="package"
                :style="item.styles"
            >
                <div :class="item.max === 0 ? 'new-layer-one' : 'layer-one'">
                    <div class="illustration">
                        <img v-if="item.image" :src="item.image" />
                    </div>
                    <div class="tit">
                        <span>{{ item.percentProfit }}%</span>
                        / {{ $t('year') }}
                    </div>
                    <div class="sub-tit">
                        {{ item.month }} {{ $t('months') }}
                    </div>
                    <!-- <div>
                        {{ item.month }} {{ $t('months') }}
                        {{ item.percentProfit }}% / {{ $t('year') }}
                    </div> -->
                    <div class="term">
                        <div class="font-bold">{{ $t('minimum') }}:</div>
                        <div>
                            {{ Number(item.min).toLocaleString('en-US') }}
                            <img
                                class="currency"
                                width="24"
                                src="@/assets/images/logo/xin.png"
                            />
                        </div>
                    </div>
                    <div class="term">
                        <div class="font-bold">{{ $t('maximum') }}:</div>
                        <div v-if="item.max > 0">
                            {{ Number(item.max).toLocaleString('en-US') }}
                            <img
                                class="currency"
                                width="24"
                                src="@/assets/images/logo/xin.png"
                            />
                        </div>
                        <div v-else-if="item.max === 0">
                            {{ $t('nolimit') }}
                        </div>
                    </div>
                    <div class="term">
                        <div class="font-bold">{{ $t('stopCondition') }}:</div>
                        <div class="full">
                            <div>
                                {{
                                    $t('stakingPoolReachesLimit', {
                                        poolLimit: $t('twoHundredMillionXIN'),
                                        more:
                                            item.max > 0
                                                ? $t('orAfterNumberMonths', {
                                                      number: 6,
                                                  })
                                                : undefined,
                                    })
                                }}
                            </div>
                            <div>
                                {{
                                    $t('totalProfitReachesNumberXIN', {
                                        number: $t('million', { number: 10 }),
                                    })
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="term">
                        <div class="font-bold">{{ $t('staked') }}:</div>
                        <div class="">
                            {{ item.totalStakeAmount.toLocaleString('en-US') }}
                            <img
                                class="currency"
                                width="24"
                                src="@/assets/images/logo/xin.png"
                            />
                        </div>
                    </div>
                    <div class="term">
                        <div class="font-bold">{{ $t('directBuyCoM') }}:</div>
                        {{
                            $t('percentOfF1StakingAmount', {
                                percent:
                                    index === 3 || index === 0
                                        ? '1%'
                                        : index === 4 || index === 1
                                        ? '3%'
                                        : '8%',
                            })
                        }}
                    </div>
                    <div v-if="item.month >= 12" class="term">
                        <div class="font-bold">{{ $t('perk') }}:</div>
                        <div>
                            {{ $t('receiveOnePercentOfWeeklyProfit') }}
                        </div>
                    </div>
                    <div class="button-wrap">
                        <button class="botan" @click="choosePackage(item)">
                            {{ $t('stakeNow') }}
                        </button>
                    </div>
                    <div v-if="item.max === 0" class="new-ribbon"></div>
                </div>
            </div>
        </template>
        <template v-else>
            <div
                v-for="i in 3"
                :key="i"
                class="package skeleton animate-pulse"
            ></div>
        </template>
        <b-modal
            id="stake-package"
            hide-header
            hide-footer
            dialog-class="supa-modal"
            centered
            @hidden="
                chosenPackage = null;
                form.amount = '';
            "
        >
            <div class="mi-head">{{ $t('stake') }} XIN</div>
            <div v-if="chosenPackage" class="mi-confirm">
                {{ $t('chosenPackage') }}:
                <div class="pack-name">
                    {{ chosenPackage.month }} {{ $t('months') }}
                    {{ chosenPackage.percentProfit }}% / {{ $t('year') }}
                </div>
            </div>
            <form class="my-form" @submit.prevent="confirmLastTime">
                <label v-if="chosenPackage">
                    <div>
                        {{ $t('amount') }}:
                        <span class="tip">
                            <span>
                                ({{ $t('minimum') }}:
                                {{ chosenPackage.min.toLocaleString('en-US') }}
                                XIN)
                            </span>
                            -
                            <span>
                                ({{ $t('maximum') }}:
                                <template v-if="chosenPackage.max > 0">
                                    {{
                                        chosenPackage.max.toLocaleString(
                                            'en-US',
                                        )
                                    }}
                                    XIN
                                </template>
                                <template v-else-if="chosenPackage.max === 0">
                                    {{ $t('nolimit') }}
                                </template>
                                )
                            </span>
                        </span>
                    </div>
                    <input
                        required
                        v-model="form.amount"
                        :placeholder="$t('enterAmountToStake')"
                        :min="chosenPackage.min"
                        type="number"
                    />
                </label>
                <div
                    class="tip estimate"
                    :class="{ show: chosenPackage && Number(form.amount) > 0 }"
                >
                    {{ $t('estimateProfits') }}:

                    <span v-if="chosenPackage && Number(form.amount) > 0">
                        {{ ProfitPerMonth.toLocaleString('en-US') }} XIN
                    </span>
                    {{ ' ' }}
                    <span v-if="chosenPackage && Number(form.amount) > 0">
                        ({{
                            $t('afterNMonths', { number: chosenPackage.month })
                        }})
                    </span>
                </div>
            </form>
            <div class="modal-botans">
                <button
                    class="botan cancel"
                    @click="$bvModal.hide('stake-package')"
                >
                    {{ $t('cancel') }}
                </button>
                <button
                    class="botan nice-gradient confirm"
                    @click="confirmLastTime"
                >
                    {{ $t('stake') }}
                </button>
            </div>
            <button
                class="close black"
                @click="$bvModal.hide('stake-package')"
                type="button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    buyVoucherviewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
        <b-modal
            id="confirm-stake"
            hide-header
            hide-footer
            dialog-class="supa-modal"
            centered
        >
            <div class="mi-head">{{ $t('confirmStaking') }} ?</div>
            <div class="mi-confirm">
                {{ $t('confirmStakingWith') }}
                <div v-if="chosenPackage" class="pack-name">
                    {{ chosenPackage.month }} {{ $t('months') }}
                    {{ chosenPackage.percentProfit }}% / {{ $t('year') }}
                </div>
                <div v-if="chosenPackage && Number(form.amount) > 0">
                    {{ Number(form.amount).toLocaleString('en-US') }}
                    <img
                        src="@/assets/images/logo/xin.png"
                        width="18"
                        height="18"
                    />
                </div>
            </div>
            <div class="modal-botans">
                <button
                    class="botan cancel"
                    @click="$bvModal.hide('confirm-stake')"
                >
                    {{ $t('cancel') }}
                </button>
                <button
                    class="botan nice-gradient confirm"
                    @click="handleStake"
                >
                    {{ $t('confirm') }}
                </button>
            </div>
            <button
                class="close black"
                @click="$bvModal.hide('confirm-stake')"
                type="button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    buyVoucherviewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import staking1 from '@/assets/images/mockup/staking-illus-1.png';
import staking2 from '@/assets/images/mockup/staking-illus-2.png';
import staking3 from '@/assets/images/mockup/staking-illus-3.png';
import staking4 from '@/assets/images/mockup/staking-illus-4.png';
import staking5 from '@/assets/images/mockup/staking-illus-5.png';
import staking6 from '@/assets/images/mockup/staking-illus-6.png';

export default {
    name: 'StakingPackages',
    data() {
        return {
            pending: false,
            form: {
                amount: '',
            },
            chosenPackage: null,
            packagesStyle: [
                {
                    '--accent-color': '#d99a26',
                    '--glow-base': '#0e327f',
                    '--gradient-top': '#27abf2',
                    '--gradient-bottom': '#070664',
                    '--border': '#007bff',
                },
                {
                    '--accent-color': '#d99a26',
                    '--glow-base': '#0E327F00',
                    '--gradient-top': '#042168',
                    '--gradient-bottom': '#991FA9',
                    '--border': '#007bff',
                },
                {
                    '--accent-color': '#d99a26',
                    '--glow-base': '#0e327f00',
                    '--gradient-top': '#EC8212',
                    '--gradient-bottom': '#20160E',
                    '--border': '#007bff',
                },
                {
                    '--accent-color': '#d99a26',
                    '--glow-base': '#0e327f',
                    '--gradient-top': '#12FA86',
                    '--gradient-bottom': '#0700DF',
                    '--border': '#007bff',
                },
                {
                    '--accent-color': '#d99a26',
                    '--glow-base': '#0e327f',
                    '--gradient-top': '#17ADD7',
                    '--gradient-bottom': '#B40169',
                    '--border': '#007bff',
                },
                {
                    '--accent-color': '#d99a26',
                    '--glow-base': '#0e327f',
                    '--gradient-top': '#AEEC12',
                    '--gradient-bottom': '#DE7B2C',
                    '--border': '#007bff',
                },
            ],
            packageImage: [
                staking1,
                staking2,
                staking3,
                staking4,
                staking5,
                staking6,
            ],
            newStaking: [
                {
                    createdAt: '2024-10-17T04:28:42.390Z',
                    id: '6710927abfd95e3fe561ae13',
                    max: 100000000,
                    min: 100000,
                    month: 3,
                    percentProfit: 8,
                    status: true,
                },
                {
                    createdAt: '2024-10-17T04:28:42.390Z',
                    id: '6710927abfd95e3fe561ae13',
                    max: 100000000,
                    min: 100000,
                    month: 3,
                    percentProfit: 8,
                    status: true,
                },
                {
                    createdAt: '2024-10-17T04:28:42.390Z',
                    id: '6710927abfd95e3fe561ae13',
                    max: 100000000,
                    min: 100000,
                    month: 3,
                    percentProfit: 8,
                    status: true,
                },
            ],
            newPackageStyle: [
                {
                    '--accent-color': '#d99a26',
                    '--glow-base': '#0e327f',
                    '--gradient-top': '#12FA86',
                    '--gradient-bottom': '#0700DF',
                    '--border': '#007bff',
                },
                {
                    '--accent-color': '#d99a26',
                    '--glow-base': '#0e327f',
                    '--gradient-top': '#17ADD7',
                    '--gradient-bottom': '#B40169',
                    '--border': '#007bff',
                },
                {
                    '--accent-color': '#d99a26',
                    '--glow-base': '#0e327f',
                    '--gradient-top': '#AEEC12',
                    '--gradient-bottom': '#DE7B2C',
                    '--border': '#007bff',
                },
            ],
            newPackageImage: [staking4, staking5, staking6],
        };
    },
    computed: {
        ...mapGetters({
            StakePackages: 'stake/StakePackages',
        }),
        ProfitPerMonth() {
            if (this.chosenPackage && Number(this.form.amount) > 0) {
                return (
                    (this.chosenPackage.percentProfit / 12 / 100) *
                    this.chosenPackage.month *
                    this.form.amount
                );
            }
            return 0;
        },
    },
    methods: {
        async handleStake() {
            if (this.pending) {
                return;
            }
            this.pending = true;

            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('staking');

            await this.$store
                .dispatch('stake/postStaking', {
                    amount: this.form.amount,
                    month: this.chosenPackage.month,
                    stakingId: this.chosenPackage._id,
                    token,
                })
                .then((res) => {
                    if (res.status) {
                        this.$bvModal.hide('stake-package');
                        this.$bvModal.hide('confirm-stake');
                        this.$store.dispatch('info/req_getInfo');
                        this.$store.dispatch('stake/getPackages');
                        this.$store.dispatch('stake/getTotalStaking');
                        this.$store.dispatch('stake/getStakeHistory');
                    }
                });

            this.pending = false;
        },
        choosePackage(pack) {
            this.chosenPackage = pack;
            this.$bvModal.show('stake-package');
        },
        confirmLastTime() {
            this.$bvModal.show('confirm-stake');
        },
    },
    mounted() {
        this.$store.dispatch('stake/getPackages');
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 24px;
    padding: 24px 0px;
    .package {
        position: relative;
        z-index: 1;
        border-radius: 16px;
        transition: 0.2s;

        --accent-color: #d99a26;
        --glow-base: #0e327f;
        --gradient-top: #27abf2;
        --gradient-bottom: #070664;
        --border: #007bff;

        .layer-one {
            z-index: 1;
            padding: 16px;
            border-radius: inherit;
            transition: 0.2s;
            color: white;
            background-image: url('~@/assets/images/background/techy-bg-2.png'),
                url('~@/assets/images/background/techy-bg-1.png'),
                linear-gradient(transparent, var(--glow-base)),
                url('~@/assets/images/background/visual-bg-1.png'),
                linear-gradient(var(--gradient-top), var(--gradient-bottom));
            background-size: contain, cover, cover, cover, cover;
            background-position: center top, center, center, center, center;
            background-repeat: no-repeat;
            border: 1px solid var(--border);
            box-shadow: inset 0px 2px 11px 1px #b0b0b040;

            height: 100%;
            display: flex;
            flex-direction: column;
            .button-wrap {
                flex-grow: 1;
                display: flex;
                align-items: end;
            }
            .illustration {
                position: relative;
                aspect-ratio: 1.4;
                > img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    height: 70%;
                    transform: translate(-50%, -50%);
                }
            }
            .tit {
                text-align: center;
                font-size: 30px;
                font-weight: 700;
                line-height: 1.1;
                span {
                    font-size: 1.5em;
                }
            }
            .sub-tit {
                // color: var(--accent-color);
                // background: linear-gradient(rgb(7, 44, 84), rgb(18, 73, 132));
                // font-weight: bold;
                width: fit-content;
                padding: 0px 12px;
                border-radius: 4px;
                margin: auto;
                font-size: 18px;
            }
            .term {
                line-height: 27px;
                padding-top: 4px;
                padding-bottom: 4px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                font-size: 18px;
                > div:first-child {
                    font-size: 0.875em;
                    color: #f3f3f3;
                    white-space: nowrap;
                }
                .full {
                    flex-basis: 100%;
                }
            }
            .botan {
                background: #fff;
                color: #0087cc;
                font-weight: bold;
                border-radius: 100vw;
                padding: 10px 16px;
                width: 100%;
                margin: 16px auto;
                display: block;
                font-size: 18px;
                transition: 0.2s;
                outline: none;
                &:hover {
                    color: #fff;
                    background: radial-gradient(
                        ellipse at bottom,
                        #8ed7fe,
                        #2195db
                    );
                }
            }
        }

        .new-layer-one {
            z-index: 1;
            padding: 16px;
            border-radius: inherit;
            transition: 0.2s;
            color: white;
            background-image: url('~@/assets/images/mockup/glow.png'),
                url('~@/assets/images/mockup/new-staking-bg.png'),
                url('~@/assets/images/mockup/shape.png'),
                linear-gradient(var(--gradient-top), var(--gradient-bottom));
            background-size: contain, cover, cover, cover, cover;
            background-position: center top, center, center, center, center;
            background-repeat: no-repeat;
            border: 1px solid var(--border);
            box-shadow: inset 0px 2px 11px 1px #b0b0b040;

            height: 100%;
            display: flex;
            flex-direction: column;
            .button-wrap {
                flex-grow: 1;
                display: flex;
                align-items: end;
            }
            .illustration {
                top: -15px;
                position: relative;
                aspect-ratio: 1.4;
                > img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    height: 70%;
                    transform: translate(-50%, -50%);
                }
            }
            .tit {
                text-align: center;
                font-size: 30px;
                font-weight: 700;
                line-height: 1.1;
                span {
                    font-size: 1.5em;
                }
            }
            .sub-tit {
                // color: var(--accent-color);
                // background: linear-gradient(rgb(7, 44, 84), rgb(18, 73, 132));
                // font-weight: bold;
                width: fit-content;
                padding: 0px 12px;
                border-radius: 4px;
                margin: auto;
                font-size: 18px;
            }
            .term {
                line-height: 27px;
                padding-top: 4px;
                padding-bottom: 4px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                font-size: 18px;
                > div:first-child {
                    font-size: 0.875em;
                    color: #f3f3f3;
                    white-space: nowrap;
                }
                .full {
                    flex-basis: 100%;
                }
            }
            .botan {
                background-color: #fff;
                color: #0087cc;
                font-weight: bold;
                border-radius: 100vw;
                padding: 10px 16px;
                width: 100%;
                margin: 16px auto;
                display: block;
                font-size: 18px;
                transition: 0.2s;
                outline: none;
                &:hover {
                    color: #fff;
                    background: radial-gradient(
                        ellipse at bottom,
                        #8ed7fe,
                        #2195db
                    );
                }
            }
        }

        &.skeleton {
            min-height: 550px;
            background-color: #dddddd;
        }
    }
    @media (min-width: 992px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
.my-form {
    > label {
        display: block;
        margin: 0px;

        > .labeling {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        > input {
            box-shadow: 0px 0px 6px 2px #0000001c;
            border-radius: 6px;
            display: block;
            width: 100%;
            color: #000;
            padding: 4px 10px;
            margin: 5px 0px;
            outline-color: #0087cc;
            border: 1px solid #adadad;
        }
    }
}
.modal-botans {
    display: flex;
    align-items: center;
    gap: 16px;

    .botan {
        flex: 1;
        color: white;
        padding: 8px 16px;
        border-radius: 100vw;
        border: 2px solid transparent;
        &.cancel {
            color: #0087cc;
            border-color: #0087cc;
        }
    }
}
.pack-name {
    font-weight: 600;
    color: #0087cc;
}
.tip {
    color: #0087cc;
    font-size: 0.875em;
    display: inline-block;
    > span {
        display: inline-block;
    }
    &.estimate {
        color: #fb923c;
        line-height: 1.2;
        min-height: 1.2em;
        opacity: 0;
        transition: 0.2s;
        &.show {
            opacity: 1;
        }
    }
}
</style>
