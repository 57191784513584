<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.9969 9.99934C14.4469 9.98427 12.9223 9.60398 11.5469 8.88934C10.4552 8.30376 9.23567 7.99732 7.99687 7.99732C6.75808 7.99732 5.53853 8.30376 4.44687 8.88934L3.54688 7.10934C4.91706 6.38093 6.4451 6 7.99687 6C9.54865 6 11.0767 6.38093 12.4469 7.10934C13.5385 7.69492 14.7581 8.00137 15.9969 8.00137C17.2357 8.00137 18.4552 7.69492 19.5469 7.10934L20.4469 8.88934C19.0714 9.60398 17.5468 9.98427 15.9969 9.99934Z"
            fill="#3C3C3C"
        />
        <path
            d="M15.9969 17.9993C14.4469 17.9843 12.9223 17.604 11.5469 16.8893C10.4552 16.3038 9.23567 15.9973 7.99687 15.9973C6.75808 15.9973 5.53853 16.3038 4.44687 16.8893L3.54688 15.1093C4.91706 14.3809 6.4451 14 7.99687 14C9.54865 14 11.0767 14.3809 12.4469 15.1093C13.5385 15.6949 14.7581 16.0014 15.9969 16.0014C17.2357 16.0014 18.4552 15.6949 19.5469 15.1093L20.4469 16.8893C19.0714 17.604 17.5468 17.9843 15.9969 17.9993Z"
            fill="#3C3C3C"
        />
    </svg>
</template>
<script>
export default {
    name: 'ApproximationIcon',
};
</script>
