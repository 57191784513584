import { render, staticRenderFns } from "./PopupConfirm.vue?vue&type=template&id=23506c49&scoped=true&"
import script from "./PopupConfirm.vue?vue&type=script&lang=js&"
export * from "./PopupConfirm.vue?vue&type=script&lang=js&"
import style0 from "./PopupConfirm.vue?vue&type=style&index=0&id=23506c49&prod&lang=scss&scoped=true&"
import style1 from "./PopupConfirm.vue?vue&type=style&index=1&id=23506c49&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23506c49",
  null
  
)

export default component.exports