import { typeOPT, LIMIT_PAGINATION } from '../../components/marketplace/const'


const axios = require('../axios.config').default;

export default {
  namespaced: true,

  state: () => ({
    products: [],
    pagination: {
      totalPages: "",
      totalResults: "",
      page: 0
    },
    resultOTP: "",
    isBuySuccess: false,
    hasQuery: false,
    isWaitingReSendOPT: false,
    paramsQuery: {
      sortBy: undefined,
      page: 1,
      limit: LIMIT_PAGINATION,
      minPrice: undefined,
      maxPrice: undefined,
      currency: undefined,
      itemName: undefined,
      itemGroup: undefined,
      itemNumber: undefined,
      itemType: undefined,
      itemId: undefined
    },
    filter: undefined
  }),

  getters: {
    Products: (state) => state.products,
    ResultOTP: (state) => state.resultOTP,
    getParamsQuery: (state) => state.paramsQuery,
    getPagination: (state) => state.pagination,
    getIsBuySuccess: (state) => state.isBuySuccess,
    getIsWaitingReSendOPT: (state) => state.isWaitingReSendOPT,
    getFilter: (state) => state.filter
  },

  actions: {
    async getProducts({ state, commit}) {
      return axios.get(`market`, { params: { ...state.paramsQuery }}).then((res) => {
        if (res && res.data) {
          commit('SET_PRODUCTS', res.data);
          commit('SET_PAGINATION', res.data)
        }
      });
    },

    async sentOTP({commit}) {
      return axios.get(`user/get-otp?type=${typeOPT.BUY_ITEM}`).then((res) => {
        if (res) {
          commit('SET_RESULT_OPT', res.message);
          commit('UPDATE_IS_WAITING_RESEND_OPT', res.status)
        }
        return res
      });
    },

    async buyItem({ commit }, body) {
      return axios.post(`/market/buy`, {
        otp: body.otp,
        marketItemOid: body.marketItemOid,
        currency: body.currency,
      }).then((res) => {
        if (res.status) {
          commit('UPDATE_IS_BUY_SUCCESS');
        }
      });
    },

    async getFilter({commit}) {
      return axios.get(`market/item-categories`).then((res) => {
        if(res && res.data) {
          commit('SET_FILTER', res.data)
        }
      })
    }

  },
  mutations: {
    SET_PRODUCTS(state, data) {
      state.products = data.results;
    },
    SET_PAGINATION(state, data) {
      state.pagination.totalPages = data.totalPages
      state.pagination.totalResults = data.totalResults
      state.pagination.page = data.page
    },
    SET_RESULT_OPT(state, data) {
      state.resultOTP = data;
    },
    UPDATE_PARAMS_QUERY(state, updatedParams) {
      state.paramsQuery = { ...state.paramsQuery, ...updatedParams };
    },
    UPDATE_IS_BUY_SUCCESS(state) {
      state.isBuySuccess = !state.isBuySuccess;
    },
    UPDATE_IS_WAITING_RESEND_OPT(state, value) {
      state.isWaitingReSendOPT = value;
    },
    SET_FILTER(state, data) {
      state.filter = data
    }
  },
};
