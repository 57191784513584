<template lang="">
  <div>
      <MarketPlaceNFT />
  </div>
</template>
<script lang="ts">
import MarketPlaceNFT from '../../../components/marketplace/marketplaceNFT/index.vue'

export default {
  name: 'MarketPlaceView',
  components: { MarketPlaceNFT }
}
</script>
<style lang="">

</style>
