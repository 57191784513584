<template>
    <div class="staking-statistic-container">
        <div class="staking-pool">
            <StakingPool />
        </div>
        <div class="staking-share">
            <StakingShare />
        </div>
    </div>
</template>
<script>
import StakingShare from './StakingShare.vue';
import StakingPool from './StakingPool.vue';

export default {
    name: 'StakingStatistic',
    components: {
        StakingPool,
        StakingShare,
    },
};
</script>

<style lang="css" scoped>
.staking-statistic-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 16px;
}

.staking-pool,
.staking-share {
    grid-column: span 12;
}

@media (min-width: 992px) {
    .staking-pool {
        grid-column: span 8;
    }

  .staking-share {
    grid-column: span 4;
  }
}
</style>
