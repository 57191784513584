<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M23.9775 8.0685L22.62 2.6355C22.4535 1.968 21.8535 1.5 21.165 1.5H2.8365C2.148 1.5 1.548 1.968 1.38 2.6355L0.0225 8.0685C0.0075 8.127 0 8.1885 0 8.25C0 10.317 1.5975 12 3.5625 12C4.704 12 5.7225 11.4315 6.375 10.5495C7.0275 11.4315 8.046 12 9.1875 12C10.329 12 11.3475 11.4315 12 10.5495C12.6525 11.4315 13.6695 12 14.8125 12C15.9555 12 16.9725 11.4315 17.625 10.5495C18.2775 11.4315 19.2945 12 20.4375 12C22.4025 12 24 10.317 24 8.25C24 8.1885 23.9925 8.127 23.9775 8.0685Z"
            fill="currentColor"
        />
        <path
            d="M20.4375 13.4992C19.416 13.4992 18.4455 13.1872 17.625 12.6172C15.984 13.7587 13.641 13.7587 12 12.6172C10.359 13.7587 8.016 13.7587 6.375 12.6172C5.5545 13.1872 4.584 13.4992 3.5625 13.4992C2.826 13.4992 2.1315 13.3267 1.5 13.0327V20.9992C1.5 21.8272 2.172 22.4992 3 22.4992H9V16.4992H15V22.4992H21C21.828 22.4992 22.5 21.8272 22.5 20.9992V13.0327C21.8685 13.3267 21.174 13.4992 20.4375 13.4992Z"
            fill="currentColor"
        />
    </svg>
</template>
<script>
export default {
    name: 'MaketPlaceIcon',
};
</script>
