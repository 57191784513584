const currency = {
    xin: 'xinCoin',
    USTD: 'USDT',
    USDT_xinCoin: 'USDT-xinCoin',
};

const typeOPT = {
    BUY_ITEM: 'BUY_MARKET_ITEM',
    SELL_ITEM: 'SELL_MARKET_ITEM',
};

const TIME_RESENT_OPT = 60;
const LIMIT_PAGINATION = 15;

const sortItemBy = {
    PRICE_DESC: 'price:desc',
    PRICE_ASC: 'price:asc',
    ALPHABET_DESC: 'alphabet:desc',
    ALPHABET_ASC: 'alphabet:asc',
};

const itemGroup = {
    NFT: 'nft',
    NODE: 'node',
    // VOUCHER: 'voucher',
};

const itemType = {
    NFT_NODE: {
        NORMAL: 'NORMAL',
        BUSINESS: 'BUSINESS',
    },
    VOUCHER: {
        DISCOUNT_BUY_NODE: 'DISCOUNT_BUY_NODE',
        MOVIE_TICKET_EXCHANGE: 'MOVIE_TICKET_EXCHANGE',
        XIN_EXCHANGE: 'XIN_EXCHANGE',
        DISCOUNT_BUY_ESIM: 'DISCOUNT_BUY_ESIM',
    },
};

const filters = [
    {
        itemGroup: 'nft',
    },
    {
        itemGroup: 'node',
        type: ['NORMAL', 'BUSINESS'],
    },
    {
        itemGroup: 'voucher',
    },
];

export function formatLabel(key) {
    switch (key) {
        case itemGroup.NFT:
            return 'NFT';
        case itemGroup.NODE:
            return 'NODE';
        case itemGroup.VOUCHER:
            return 'VOUCHER';
        case itemType.NFT_NODE.NORMAL:
            return 'normalPlan';
        case itemType.NFT_NODE.BUSINESS:
            return 'businessPlan';
        case itemType.VOUCHER.DISCOUNT_BUY_NODE:
            return 'discountForBuyingNode';
        case itemType.VOUCHER.MOVIE_TICKET_EXCHANGE:
            return 'movieTicketExchange';
        case itemType.VOUCHER.XIN_EXCHANGE:
            return 'XIN Points Exchange';
        case itemType.VOUCHER.DISCOUNT_BUY_ESIM:
            return 'disCountforBuyingeSIM';
        case sortItemBy.PRICE_ASC:
            return 'ascendingPrice';
        case sortItemBy.PRICE_DESC:
            return 'descendingPrice';
        case sortItemBy.ALPHABET_DESC:
            return 'descendingAlphabet';
        case sortItemBy.ALPHABET_ASC:
            return 'ascendingAlphabet';
        default:
            return key;
    }
}

// Store: marketplace-nft - Page marketplace Nft
const DISPATCH_GET_MARKET_PRODUCTS = 'marketplaceNft/getProducts';
const GETTER_PARAMS_QUERY_MARKET_PRODUCTS = 'marketplaceNft/getParamsQuery';
const GETTER_GET_IS_WAITING_RESENT_OPT = 'marketplaceNft/getIsWaitingReSendOPT';
const COMMIT_CHANGE_QUERY_MARKET_PRODUCTS =
    'marketplaceNft/UPDATE_PARAMS_QUERY';
const DISPATCH_GET_FILTER = 'marketplaceNft/getFilter';

// Store: marketplace - Page: MyMarket/Product
const DISPATCH_GET_MYMARKET_PRODUCTS = 'marketplace/getSoldItem';
const GETTTER_PARAMS_QUERY_MYMARKET_PRODUCT = 'marketplace/getParamsQuery';
const COMMIT_CHANGE_QUERY_MYMARKET_PRODUCTS = 'marketplace/UPDATE_PARAMS_QUERY';

// Store: marketplace - Page: MyMarket/History
const DISPATCH_GET_MYMARKET_HISTORY_PRODUCTS = 'marketplace/getMyMarketHistory';
const GETTTER_PARAMS_QUERY_MYMARKET_HISTORY_PRODUCT =
    'marketplace/getMyMaketHistoryQuery';
const COMMIT_CHANGE_QUERY_MYMARKET_HISTORY_PRODUCTS =
    'marketplace/UPDATE_PARAMS_QUERY_MYMAKET_HISTORY';

// eslint-disable-next-line import/prefer-default-export
export {
    currency,
    typeOPT,
    TIME_RESENT_OPT,
    sortItemBy,
    itemGroup,
    itemType,
    LIMIT_PAGINATION,
    DISPATCH_GET_MARKET_PRODUCTS,
    GETTER_PARAMS_QUERY_MARKET_PRODUCTS,
    COMMIT_CHANGE_QUERY_MARKET_PRODUCTS,
    DISPATCH_GET_MYMARKET_PRODUCTS,
    GETTTER_PARAMS_QUERY_MYMARKET_PRODUCT,
    COMMIT_CHANGE_QUERY_MYMARKET_PRODUCTS,
    DISPATCH_GET_MYMARKET_HISTORY_PRODUCTS,
    GETTTER_PARAMS_QUERY_MYMARKET_HISTORY_PRODUCT,
    COMMIT_CHANGE_QUERY_MYMARKET_HISTORY_PRODUCTS,
    DISPATCH_GET_FILTER,
    GETTER_GET_IS_WAITING_RESENT_OPT,
    filters,
};
